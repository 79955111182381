import React    from 'react'
import ReactDOM from 'react-dom'

import 'babel-polyfill'

import App from './App'

import View  from './providers/view'
import Theme from './providers/theme'

import './styles/index.scss'
import './styles/icons.css'

import AppComponent from './providers/app'

const $$ = (id) => document.getElementById(id)

View.setViewport()
Theme.apply()
AppComponent.init()

ReactDOM.render(<App />, $$('app'))
class Static {
    constructor() {
        let currentYear = new Date().getUTCFullYear()
        this.years = []
        for (let x = 0; x < 20; x++) {
            this.years.push(currentYear - x)
        }
        this.monthMap = {
            jan: 'January',
            feb: 'February',
            mar: 'March',
            apr: 'April',
            may: 'May',
            jun: 'June',
            jul: 'July',
            aug: 'August',
            sep: 'September',
            oct: 'October',
            nov: 'November',
            dec: 'December',
        }
        this.months = Object.keys(this.monthMap)
    }
}

let StaticSingleton = new Static()

export default StaticSingleton
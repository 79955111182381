import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router'
import styled from 'styled-components'

import IncomeRow from './IncomeRow'

const Table = styled.div`
    width: 100%;
`
export default class IncomeTable extends Component {
    state = {selected: null}
    select(income_id) {
        this.setState({selected: income_id})
    }
    render() {
        const {incomes} = this.props
        const {selected} = this.state
        if (selected) return <Redirect push to={`/income/${selected}/view`} />
        if (!incomes.length) return 'No income records to show.'
        return <Table>
            {incomes.sort((a, b) => {
                return (a.date < b.date ? 1
                     : (a.date > b.date ? -1
                     : (a.amount < b.amount ? 1
                     : (a.amount > b.amount ? -1
                     : 0))))
            }).map((income, k) => (
                <IncomeRow
                    index={k} key={k}
                    income={income}
                    select={() => this.select(income.income_id)}
                />
            ))}
        </Table>
    }

}
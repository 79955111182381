import Static   from './static'
import API      from './api'
import utils    from './utilities'

import ExpenseData from './expenses'

const currentMonth = new Date().getUTCMonth()
const currentYear  = new Date().getUTCFullYear()

const fix = x => Math.round(x*100)/100

class Report {

    constructor() {
        this._category_map = null
        this._expenses     = {}
        this._incomes      = {}
        this._report_exps  = {}
        this._report_incs  = {}
        this._year         = currentYear
    }

    setYear(y) {
        this._year = y
    }

    getCalendarYear(y) {
        y = y || this._year
        let reportOnMonths = y == currentYear ? Static.months.slice(0, currentMonth+1) : Static.months

        return this.getYear(y).then(() => {
            let expenseTotal = 0
            let incomeTotal = 0
            let differenceTotal = 0
            let rows = reportOnMonths.map(m => {
                const mo = Static.monthMap[m]
                let monthlyExpenses = 0
                let monthlyIncomes  = 0
                if (this._report_exps.hasOwnProperty(y)) {
                    const rep_year = this._report_exps[y]
                    if (rep_year.hasOwnProperty(mo)) {
                        rep_year[mo].forEach(exp => {
                            monthlyExpenses += exp.amount
                        })
                    }
                }
                if (this._report_incs.hasOwnProperty(y)) {
                    const rep_year = this._report_incs[y]
                    if (rep_year.hasOwnProperty(mo)) {
                        rep_year[mo].forEach(inc => {
                            monthlyIncomes += inc.amount
                        })
                    }
                }

                monthlyExpenses = fix(monthlyExpenses)
                monthlyIncomes = fix(monthlyIncomes)
                expenseTotal += monthlyExpenses
                incomeTotal += monthlyIncomes
                return {
                    Month: utils.monthDisplayName(m),
                    Expenses: monthlyExpenses,
                    Income: monthlyIncomes,
                    Difference: fix(monthlyIncomes - monthlyExpenses)
                }
            })
            expenseTotal = fix(expenseTotal)
            incomeTotal = fix(incomeTotal)
            differenceTotal = fix(incomeTotal - expenseTotal)
            return Promise.resolve({
                rows, expenseTotal, incomeTotal, differenceTotal
            })
        })
    }

    getYear(y) {
        y = y || currentYear

        let promises = [
            this._expenses[y] ? Promise.resolve(this._expenses[y])
                              : API.get(`/expenses/${y}`),
            this._incomes[y]  ? Promise.resolve(this._incomes[y])
                              : API.get(`/incomes/${y}`)
        ]

        return Promise.all(promises).then(([exp, inc]) => {
            if (exp.data) this._expenses[y] = exp.data
            if (inc.data) this._incomes[y]  = inc.data

            this._report_exps[y] = this._report_exps[y] || {}
            this._report_incs[y] = this._report_incs[y] || {}
            Object.values(Static.monthMap).forEach(m => {
                this._report_exps[y][m] = this._report_exps[y][m] || []
                this._report_incs[y][m] = this._report_incs[y][m] || []
            })

            if (!exp.data && !inc.data) return

            this._expenses[y].forEach(expense => {
                let [_, m, d] = expense.date.split('-')
                m = parseInt(m) - 1
                m = Static.monthMap[Static.months[m]]
                this._report_exps[y][m].push(expense)
            })

            this._incomes[y].forEach(income => {
                let [_, m, d] = income.date.split('-')
                m = parseInt(m) - 1
                m = Static.monthMap[Static.months[m]]
                this._report_incs[y][m].push(income)
            })
        })
    }

    getCategories() {
        if (this._category_map) return Promise.resolve(this._category_map)
        return API.get('/expense/categories').then(catreq => {
            let cats = catreq.data
            let category_map = {}

            cats.forEach(category => category_map[category.category_id] = category)

            cats.forEach(category => {
                let lvl = 0
                let cur = category
                while (cur.parent_id > 0) {
                    lvl++
                    cur = category_map[cur.parent_id]
                }
                category_map[category.category_id].level = lvl
            })

            this._category_map = category_map

            return Promise.resolve(this._category_map)
        })
    }

    getCategoryTotals({month, category_id, year}) {
        year = year || this._year
        let ly = year - 1

        let this_year  = this.getYear(year)
        let last_year  = this.getYear(ly)
        let categories = this.getCategories()

        let promises = [this_year, last_year, categories]

        return Promise.all(promises).then(([t, l, cats]) => {
            let lvl = category_id ? cats[category_id].level : 0

            let categorized = {
                [year]:{},
                [ly]:{},
                list: []
            }

            let tye = [];
            let lye = [];
            if (!month) {
                Object.values(this._report_exps[year]).forEach(arr => {
                    tye = tye.concat(arr)
                })
                Object.values(this._report_exps[ly]).forEach(arr => {
                    lye = lye.concat(arr)
                })
            }
            else {
                tye = this._report_exps[year][month]
                lye = this._report_exps[year][month]
            }

            const processYear = year => {
                return expense => {
                    let cat_id = expense.category_tree[lvl] || 0
                    let label = 'Uncategorized'
                    if (category_id) {
                        if (cat_id != category_id) return false;
                        cat_id = expense.category_tree[lvl+1] || cat_id
                        if (cat_id != category_id ) label = cats[cat_id].name
                    }
                    else if (cat_id) {
                        label = cats[cat_id].name
                    }
                    if (!categorized[year][cat_id]) categorized[year][cat_id] = {
                        label,
                        value: 0
                    }
                    categorized[year][cat_id].value += expense.amount
                    return true;
                }
            }

            let top = tye.filter(processYear(year))
            lye.forEach(processYear(ly))

            top = top.map(exp => (
                {
                    ...exp,
                    // temp patch for missing categories
                    category: (cats[exp.category_tree[exp.category_tree.length -1]] || {name:'N/A'}).name,
                }
            ))

            return Promise.resolve([categorized[year], categorized[ly], top])
        })

    }

}

var ReportSingleton = new Report()
export default ReportSingleton
import React, { Fragment } from 'react'
import Styled from 'styled-components'
import { Redirect } from 'react-router'

import Listener from '../../bases/Listener'

import { Button } from '../Sidebar/Buttons'
import SidebarWrapper from '../Sidebar'
import Form           from '../Form'
import Choice         from '../Choice'
import ChoiceTree     from '../ChoiceTree'
import Title          from '../Sidebar/Title'
import EditMenu       from '../Sidebar/EditMenu'
import EditText       from '../Sidebar/EditText'

import IncomeData from '../../providers/incomes'

const MenuItem = Styled.div`
    width: 100%;
    font-size: 1rem;
    padding: .5rem;
    padding-left: 1rem;
    cursor: pointer;
    &:hover{
        background-color: ${p => p.theme.highlight};
    }
`

const defaultState = {
    back: false,
    edit: false,
    editing: null,
    edits: {},
    creating: null,
    creates: {},
    loading: false
}

class ViewSidebar extends React.Component {
    state = defaultState
    constructor(props){
        super(props)
        this._refs = {}
    }
    createEdit({name, value}) {
        const {creates} = this.state
        this.setState({creates:{...creates, [name]:value}})
    }
    edit({name, value}, saveAfter) {
        const {edits} = this.state
        this.setState({edits:{...edits, [name]: value}}, () => {
            if (saveAfter) this.save()
        })
    }
    event(msg, data) {
        if (msg == 'incomes.deleted') {
            this.setState({deleted: data})
        }
        if (msg == 'incomes') {
            this.setState(defaultState)
        }
        if (msg == 'methods.created') {
            const {method_id} = data
            this.setState({
                creating: null,
                creates: {},
                editing: 'method_id',
                edits :{method_id}
            }, () => this.save())
        }
        if (msg == 'categories.created') {
            const {category_id} = data
            this.setState({
                creating: null,
                creates: {},
                editing: 'category_id',
                edits: {category_id}
            }, () => this.save())
        }
    }
    save() {
        const {editing, creating, edits} = this.state
        if (creating) {
            const {creates} = this.state
            this.setState({loading: true}, () => {
                if (creates.method_id) {
                    IncomeData.createMethod(creates.method_id)
                }
                else if(creates.category_id) {
                    IncomeData.createCategory(creates.category_id)
                }
            })
        }
        else if (editing) {
            const {edits} = this.state
            const {income_id} = this.props.match.params
            IncomeData.update(income_id, edits)
            this.setState({edits:{}, editing: null, edit: false})
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const state = this.state
        const types = ['editing', 'creating']
        types.forEach(type => {
            const it = state[type]
            if (prevState[type] == null && !!it) {
                if(this._refs[it]) {
                    this._refs[it].focus()
                    if (this._refs[it].type == 'text') {
                        this._refs[it].setSelectionRange(
                            0, this._refs[it].value.length
                        )
                    }
                }
            }
        })
    }
    dataType(name) {
        const types = {
            name: 'text',
            original_amount: 'number'
        }
        if (types[name]) return types[name]
    }
    title(name) {
        const titles = {
            original_amount: 'amount',
            method_id: 'Payment Method',
            category_id: 'Category'
        }
        if (titles[name]) return titles[name]
        return name
    }
    create(creating) {
        this.setState({creating, creates:{[creating]:''}})
    }
    delete() {
        const {income_id} = this.props.match.params
        this.setState(
            {loading: true},
            () => IncomeData.deleteIncome(income_id)
        )
    }
    reset() {
        this.setState(defaultState)
    }
    render() {
        const {deleted}  = this.state
        if (deleted) return <Redirect to={
            `/incomes/${deleted.year}/${deleted.month}`
        } />
        const path       = this.props.match.params
        const income     = IncomeData.info(path.income_id)
        const methods    = IncomeData.getMethods()
        const categories = IncomeData.getCategories()
        let method_items = {}
        let category_items = {}
        if (methods) {
            Object.keys(methods).forEach(id => {
                method_items[id] = methods[id].name
            })
        }
        if (categories) {
            Object.keys(categories).forEach(id => {
                category_items[id] = categories[id].name
            })
        }
        const editItems = key => {
            const items = {
                method_id   : method_items,
                category_id : category_items,
            }
            return items[key]
        }
        if(this.state.back) return <Redirect push to={
            `/incomes/${income.year}/${income.month}`
        } />
        const {editing, edit, edits, creating, creates, loading, deleting} = this.state
        return <Fragment>
            <SidebarWrapper>
                {!loading && !edit
                    ?
                    <Button position="1" icon="arrow-left" onClick={() => this.setState({back: true})} />
                    : !loading ?
                    <Button position="1" icon="cross" onClick={() => this.reset()} />
                    : null
                }
                {!loading && !editing
                    ? <Fragment>
                        <Button position="2" icon="edit" onClick={
                            () => this.setState(x => ({edit: !x.edit}))
                        } />
                        <Button position="3" icon="trash-2" onClick={
                            () => this.setState({deleting: true})
                        } />
                      </Fragment>
                      : !loading ?
                      <Button position="2" icon="checkmark" onClick={() => this.save()} />
                      : null
                }
            </SidebarWrapper>
            {loading
                ?
                <EditMenu>loading...</EditMenu>
                :
                null
            }
            {!deleting && !loading && creating ?
                <EditMenu>
                    <Form send={() => this.save()}>
                        <EditText name={creating}
                            ref={x => {this._refs[creating] = x}}
                            id={creating}
                            type="text"
                            value={creates[creating]}
                            onChange={e => this.createEdit(e.target)} />
                    </Form>
                </EditMenu>
                :
                null
            }
            {!deleting && !loading && !creating && edit && !editing
                ?
                <EditMenu>
                    <MenuItem onClick={
                        () => this.setState({editing:'name'})
                    }>Name</MenuItem>
                    <MenuItem onClick={
                        () => this.setState({editing:'method_id'})
                    }>Payment Method</MenuItem>
                    <MenuItem onClick={
                        () => this.setState({editing:'category_id'})
                    }>Category</MenuItem>
                    <MenuItem onClick={
                        () => this.setState({editing:'date'})
                    }>Date</MenuItem>
                    <MenuItem onClick={
                        () => this.setState({editing:'amount'})
                    }>Amount</MenuItem>
                </EditMenu>
                :
                null
            }
            {!deleting && !loading && !creating && !!editing && editing.match(/_id$/)
                ?
                <EditMenu>
                    <Choice
                        name={editing}
                        title={this.title(editing)}
                        current={
                            edits.hasOwnProperty(editing) ? edits[editing]
                                                         : income[editing] }
                        items={editItems(editing)}
                        onChoose={e => this.edit({name:editing, value:e}, true)}
                        createNew={() => this.create(editing)}
                    />
                </EditMenu>
                : !loading && !creating && !!editing ?
                <EditMenu>
                    <Form send={() => this.save()}>
                        <Title>{this.title(editing)}</Title>
                        <EditText name={editing}
                                  ref={x => {this._refs[editing] = x}}
                                  id={editing}
                                  step="0.01"
                                  type={this.dataType(editing)}
                                  value={edits.hasOwnProperty(editing) ?
                                    edits[editing]
                                    : income[editing]
                                  }
                                  onChange={e => this.edit(e.target)} />
                    </Form>
                </EditMenu>
                : null
            }
            {!loading && deleting ?
                <EditMenu>
                    <Title>Delete Income?</Title>
                    <MenuItem onClick={() => this.delete()}>Yes</MenuItem>
                    <MenuItem onClick={() => this.setState({deleting: false})}>No</MenuItem>
                </EditMenu>
                : null
            }
        </Fragment>
    }
}

export default Listener(ViewSidebar, ['methods', 'incomes', 'categories'])
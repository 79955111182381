import React, { Fragment } from 'react'
import Styled from 'styled-components'
import { Redirect } from 'react-router'

import { Button, EditButton } from '../Sidebar/Buttons'
import EditMenu, { MenuItem } from '../Sidebar/EditMenu'

import SidebarWrapper from '../Sidebar'
import Form           from '../Form'
import Choice         from '../Choice'
import ChoiceTree     from '../ChoiceTree'
import Calendar       from '../Calendar'
import Listener       from '../../bases/Listener'
import Icon           from '../Icon'
import Title          from '../Sidebar/Title'
import EditText       from '../Sidebar/EditText'

import ExpenseData from '../../providers/expenses'
import Keyboard    from '../../providers/keyboard'
import App         from '../../providers/app'


const defaultState = {
    back: false,
    edit: false,
    editing: null,
    edits: {},
    creating: null,
    creates: {},
    loading: false,
    deleting: false,
    deleted: false,
    reimbursing: false,
    payback: {},
}

class ViewSidebar extends React.Component {
    constructor(props){
        super(props)
        this._refs = {}
        this.state = defaultState

        const path       = props.match.params
        const expense    = ExpenseData.info(path.expense_id)
        if (expense) this.state.payback.paid = expense.date
    }
    componentWillMount() {
        this._keyboard = Keyboard.listen('key', this.keyPressed.bind(this))
    }
    componentWillUnmount() {
        Keyboard.ignore(this._keyboard)
    }
    keyPressed(msg, data) {
        if (data.code == "Escape") {
            if (this.state.edit) this.reset()
            else this.setState({back: true})
        }

        if (this.state.editing) return

        if (data.code == "KeyE") {
            this.setState({edit: !this.state.edit})
        }
    }
    createEdit({name, value}) {
        const {creates} = this.state
        this.setState({creates:{...creates, [name]:value}})
    }
    edit({name, value}, saveAfter) {
        const {edits} = this.state
        this.setState({edits:{...edits, [name]: value}}, () => {
            if (saveAfter) this.save()
        })
    }
    edit_payback({name, value}) {
        const {payback} = this.state
        this.setState({payback:{...payback, [name]: value}})
    }
    save_payback() {
        const {payback} = this.state
        const {expense_id} = this.props.match.params
        this.setState({loading: true}, () => {
            ExpenseData.createPayback(expense_id, payback)
        })
    }
    event(msg, data) {
        if (msg == 'expenses.deleted') {
            this.setState({deleted: data})
        }
        if (msg == 'expenses' || msg == 'expenses.payback') {
            this.reset()
        }
        if (msg == 'methods.created') {
            const {method_id} = data
            this.setState({
                creating: null,
                creates: {},
                editing: 'method_id',
                edits :{method_id}
            }, () => this.save())
        }
        if (msg == 'categories.created') {
            const {category_id} = data
            const {edits} = this.state
            let category_tree =  []
            if (edits.category_tree)
                category_tree = edits.category_tree.map(x => parseInt(x))
            category_tree.push(parseInt(category_id))
            this.setState({
                creating: null,
                creates: {},
                editing: 'category_tree',
                edits: {category_tree}
            }, () => this.save())
        }
    }
    save() {
        const {editing, creating, edits} = this.state
        if (creating) {
            const {creates} = this.state
            this.setState({loading: true}, () => {
                if (creates.method_id) {
                    ExpenseData.createMethod(creates.method_id)
                }
                else if(creates.category_id) {
                    const tree = edits.category_tree || []
                    let parent_id = tree[tree.length - 1] || 0
                    ExpenseData.createCategory(creates.category_id, parent_id)
                }
            })
        }
        else if (editing) {
            const {edits} = this.state
            const {expense_id} = this.props.match.params
            ExpenseData.update(expense_id, edits)
            this.setState({edits:{}, editing: null, edit: false})
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const state = this.state
        const types = ['editing', 'creating']
        types.forEach(type => {
            const it = state[type]
            if (prevState[type] == null && !!it) {
                if(this._refs[it]) {
                    let el = this._refs[it]
                    el.focus()
                    /**
                     * Some user agents are annoying and don't allow for us to
                     * sel a range within a number input, so hack around that
                     * by changing the input to 'text', then sel, then go back.
                     */
                    let swap = el.type == 'number' ? 'number' : false

                    if (swap) el.type = 'text'

                    if (el.type == 'text') {
                        setTimeout(() => {
                            el.setSelectionRange(0, el.value.length)
                            if (swap) el.type = swap
                        }, 0)
                    }
                }
            }
        })
    }
    dataType(name) {
        const types = {
            name: 'text',
            original_amount: 'number'
        }
        if (types[name]) return types[name]
    }
    title(name) {
        const titles = {
            original_amount: 'amount'
        }
        if (titles[name]) return titles[name]
        return name
    }
    create(creating) {
        this.setState({creating, creates:{[creating]:''}})
    }
    delete() {
        const {expense_id} = this.props.match.params
        this.setState(
            {loading: true},
            () => ExpenseData.deleteExpense(expense_id)
        )
    }
    reset() {
        let newState = defaultState
        const path       = this.props.match.params
        const expense    = ExpenseData.info(path.expense_id)
        if (expense) newState.payback.paid = expense.date
        this.setState(newState)
    }
    render() {
        const {deleted} = this.state
        if (deleted) return <Redirect to={
            `/expenses/${deleted.year}/${deleted.month}`
        } />
        const path       = this.props.match.params
        const expense    = ExpenseData.info(path.expense_id)
        const methods    = ExpenseData.getMethods()
        const payers     = ExpenseData.getPayers()
        const categories = ExpenseData.getCategories()
        const method_items = {}
        const payer_items = {}
        if (methods) {
            Object.keys(methods).forEach(method_id => {
                method_items[method_id] = methods[method_id].name
            })
        }
        if (payers) {
            Object.keys(payers).forEach(payer_id => {
                payer_items[payer_id] = payers[payer_id].name
            })
        }
        if(this.state.back) return <Redirect push to={
            `/expenses/${expense.year}/${expense.month}`
        } />
        const {editing, edit, edits, creating, creates, loading, deleting, payback, reimbursing} = this.state
        if (loading) return <Fragment>
            <SidebarWrapper />
            <EditMenu>loading...</EditMenu>
        </Fragment>
        if (deleting) return <Fragment>
            <SidebarWrapper>
                <Button position="1" icon="x" onClick={() => this.reset()} />
            </SidebarWrapper>
            <EditMenu>
                <Title>Delete Expense?</Title>
                <MenuItem onClick={() => this.delete()}>Yes</MenuItem>
                <MenuItem onClick={() => this.setState({deleting: false})}>
                    No
                </MenuItem>
            </EditMenu>
        </Fragment>
        return <Fragment>
            <SidebarWrapper>
                {creating || editing ?
                    <Fragment>
                        <Button position="1" icon="x" onClick={() => this.reset()} />
                        <Button position="2" icon="check" onClick={() => this.save()} />
                    </Fragment>
                    : reimbursing ?
                    <Fragment>
                        <Button position="1" icon="x" onClick={() => this.reset()} />
                        <Button position="2" icon="check" onClick={() => this.save_payback()} />
                    </Fragment>
                    : edit ?
                    <Button position="1" icon="x" onClick={() => this.reset()} />
                    :
                    <Fragment>
                        <Button position="1" icon="arrow-left" onClick={
                            () => this.setState({back:true})
                        } />
                        <Button position="2" icon="edit" onClick={
                            () => this.setState(x => ({edit: !x.edit}))
                        } />
                        <Button position="3" icon="trash-2" onClick={
                            () => this.setState({deleting: true})
                        } />
                        <Button position="4" icon="user-plus" onClick={
                            () => this.setState({reimbursing: true})
                        } />
                    </Fragment>
                }
            </SidebarWrapper>
            {creating ?
                <EditMenu>
                    <Form send={() => this.save()}>
                        <EditText name={creating}
                            ref={x => {this._refs[creating] = x}}
                            id={creating}
                            type="text"
                            value={creates[creating]}
                            onChange={e => this.createEdit(e.target)} />
                    </Form>
                </EditMenu>
                :
                null
            }
            {reimbursing ?
                <EditMenu>
                    <Form send={() => this.reimburse()}>
                        <Title>Payer</Title>
                        <Choice name="payer_id"
                            current={payback.payer_id}
                            items={payer_items}
                            onChoose={e => this.edit_payback({name: 'payer_id', value: e})}
                        />
                        <Title>Amount</Title>
                        <EditText name="amount"
                            id="amount"
                            step="0.01"
                            type="number"
                            value={payback.amount || ''}
                            onChange={e => this.edit_payback(e.target)}
                        />
                        <Title>Payment Method</Title>
                        <Choice name="method_id"
                            current={payback.method_id}
                            items={method_items}
                            onChoose={e => this.edit_payback({name: 'method_id', value: e})}
                        />
                        <Title>Date</Title>
                        <Calendar year={expense.year}
                            month={expense.month}
                            lock="month"
                            current={payback.paid}
                            onChoose={e => this.edit_payback({name:'paid', value:e})}
                        />
                    </Form>
                </EditMenu>
            : null}
            {edit && !editing
                ?
                <EditMenu>
                    <MenuItem onClick={
                        () => this.setState({editing:'name'})
                    }>Name</MenuItem>
                    <MenuItem onClick={
                        () => this.setState({editing:'method_id'})
                    }>Payment Method</MenuItem>
                    <MenuItem onClick={
                        () => this.setState({editing:'category_tree'})
                    }>Category</MenuItem>
                    <MenuItem onClick={
                        () => this.setState({editing:'date'})
                    }>Date</MenuItem>
                    <MenuItem onClick={
                        () => this.setState({editing:'original_amount'})
                    }>Amount</MenuItem>
                </EditMenu>
                :
                null
            }
            {editing == 'method_id'
                ?
                <EditMenu>
                    <Choice
                        name={editing}
                        title="Payment Method"
                        current={
                            edits.hasOwnProperty(editing) ? edits[editing]
                                                         : expense[editing] }
                        items={method_items}
                        onChoose={e => this.edit({name:editing, value:e}, true)}
                        createNew={() => this.create(editing)}
                    />
                </EditMenu>
                : editing == 'category_tree'
                ? <EditMenu>
                    <ChoiceTree
                        name={editing}
                        title="Category"
                        current={
                            edits.hasOwnProperty(editing) ? edits[editing]
                                                          : expense[editing] }
                        items={categories}
                        onChoose={(e, s) => this.edit({name:editing, value:e}, s)}
                        createNew={() => this.create('category_id')}
                    />
                </EditMenu>
                : editing == 'date'
                ? <EditMenu>
                    <Calendar
                        year={expense.year}
                        month={expense.month}
                        lock="month"
                        current={
                            edits.hasOwnProperty(editing) ? edits[editing]
                                                          : expense[editing]
                        }
                        onChoose={(e, s) => this.edit({name:editing, value:e}, s)}
                    />
                </EditMenu>
                : !!editing ?
                <EditMenu>
                    <Form send={() => this.save()}>
                        <Title>{this.title(editing)}</Title>
                        <EditText name={editing}
                                  ref={x => {this._refs[editing] = x}}
                                  id={editing}
                                  step="0.01"
                                  type={this.dataType(editing)}
                                  value={edits.hasOwnProperty(editing) ?
                                    edits[editing]
                                    : expense[editing]
                                  }
                                  onChange={e => this.edit(e.target)} />
                    </Form>
                </EditMenu>
                : null
            }
        </Fragment>
    }
}

export default Listener(ViewSidebar, ['expenses', 'methods', 'categories'])
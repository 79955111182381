import React from 'react'
import Styled from 'styled-components'

const EditText = Styled.input`
    font-size: 1rem;
    margin: .5rem;
    margin-bottom: 1rem;
    border: none;
    color: ${p => p.theme.primary};
    background-color: ${p => p.theme.background};
    &:active {
        box-shadow: none;
    }
`

export default EditText
import React, { Component, Fragment } from 'react'
import Styled from 'styled-components'

import { money } from '../../providers/utilities'

import Table                             from './Table'
import { HeaderRow, HeaderField }        from './TableHeader'
import Row, { HighlightedRow, RowField } from './Row'

const PositiveAmount = Styled(RowField)`
    color: ${p => p.theme.dash.pos};
`

const NegativeAmount = Styled(RowField)`
    color: ${p => p.theme.dash.neg};
`
export default function MonthList ({onSelect, rows, totals, selected}) {
    let rowElements = <tr><td>Loading...</td></tr>

    if (rows.length) {
        rows.push({
            Month: 'Total',
            Expenses: totals.et,
            Income: totals.it,
            Difference: totals.dt
        })
        rowElements = rows.map((r, i) => {
            let RowElement = r.Month == selected ? HighlightedRow : Row
            let DiffElement = r.Difference > 0 ? PositiveAmount : NegativeAmount
            return <RowElement onClick={() => onSelect(r.Month)} key={i+1}>
                <RowField>{r.Month}</RowField>
                <RowField>{money(r.Expenses)}</RowField>
                <RowField>{money(r.Income)}</RowField>
                <DiffElement>{money(r.Difference)}</DiffElement>
            </RowElement>
        })
    }
    else {

    }

    return <Table>
        <thead>
            <HeaderRow>
                <HeaderField>Month</HeaderField>
                <HeaderField>Expenses</HeaderField>
                <HeaderField>Income</HeaderField>
                <HeaderField>Difference</HeaderField>
            </HeaderRow>
        </thead>
        <tbody>
            {rowElements}
        </tbody>
    </Table>
}
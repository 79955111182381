import Provider from './provider'
import { money } from './utilities'
import Static   from './static'

class Incomes extends Provider {

    constructor(){
        super()

        this._incomes    = {}
        this._categories = {}
        this._methods    = {}

        this._income     = {}

        this.months      = Static.months
    }

    info (income_id) {
        const key = this.incomeKey(income_id)
        return this.get(`/income/${income_id}`, key, 200, data => {
            this.setIncome(data)
        }, () => this._income[income_id])
    }

    create (income) {
        income.method_id   = parseInt(income.method_id)
        income.category_id = parseInt(income.category_id)
        income.amount      = parseFloat(income.amount)
        this.post('/income', income, 201, data => this.setIncome(data))
    }

    incomeKey (income_id) {
        return `income_${income_id}`
    }

    deleteIncome (income_id) {
        const income = this._income[income_id]
        let {year, month} = income

        this.delete(`/income/${income_id}`, 204, ok => {
            delete this._
            this._incomes[year][month] = this._incomes[year][month].filter(inc => (
                inc.income_id != income_id
            ))
            this.notify('incomes.deleted', {year, month})
        }, () => this.notify('incomes'))
    }

    setIncome (data) {
        data.amount_money = data.amount.toFixed(2)
        const date  = new Date(data.date)
        const month = this.months[date.getUTCMonth()]
        const year  = date.getUTCFullYear()
        let income  = {year, month, ...data}

        this._income[income.income_id] = income
        this._incomes[year]            = this._incomes[year] || {}
        this._incomes[year][month]     = this._incomes[year][month] || []

        let found = false
        for (let i in this._incomes[year][month]) {
            if (this._incomes[year][month][i].income_id == income.income_id) {
                this._incomes[year][month][i] = income
                found = true
                break
            }
        }
        if (!found) this._incomes[year][month].push(income)
        this._has[this.incomeKey(income.income_id)] = true
        this.notify('incomes')
    }

    sanitized (changes) {
        const strs   = ['name']
        const ints   = ['method_id', 'category_id']
        const floats = ['amount']
        Object.keys(changes).forEach(property => {
            if (ints.indexOf(property) !== -1) {
                changes[property] = parseInt(changes[property])
            }
            else if (floats.indexOf(property) !== -1) {
                changes[property] = parseFloat(changes[property])
            }
            else if (strs.indexOf(property) !== -1) {
                changes[property] = changes[property].toString()
            }
        })
        return changes
    }

    update (income_id, changes, internal) {
        changes = this.sanitized(changes)
        const original = this._income[income_id] || {}
        const fields   = Object.keys(changes)
        const differs = fields.filter(x => changes[x] !== original[x])
        if (!differs.length) return
        let updated = {...original, ...changes}
        this.put(`/income/${income_id}`, updated, 202, data => {
            this.setIncome(data)
        }, () => {
            this.setIncome(original)
        })
        this.setIncome(updated)
    }

    set (year, month, data) {
        this._incomes[year]        = this._incomes[year] || {}
        this._incomes[year][month] = data.map(income => {
            income.month        = month
            income.year         = year
            income.amount_money = income.amount.toFixed(2)
            this._income[income.income_id] = income
            this._has[this.incomeKey(income.income_id)] = true
            return income
        })
        this._has[`${year}.${month}`] = true
        return this
    }

    getMonth (year, month) {
        const key = `${year}.${month}`
        return this.get(`/incomes/${year}/${month}`, key, 200, data => {
            this.set(year, month, data)
            this.notify('incomes.month', {month})
        },  () => this._incomes[year][month])
    }

    getTotal (year, month) {
        let incomes = this.getMonth(year, month)
        if (!incomes) return null
        let sum = incomes.reduce((a,b) => (a + (b.amount||0)), 0)
        return money(sum)
    }

    getCategories () {
        return this.get('/income/categories', 'categories', 200, data => {
            data.forEach(category => {
                this._categories[category.category_id] = category
            })
            this.notify('incomes.categories')
        }, () => this._categories)
    }

    createCategory (name) {
        this.post('/income/categories', {name}, 201, data => {
            this._categories[data.category_id] = data
            this.notify('categories.created', data)
        })
    }

    getCategoryName (category_id) {
        if (!this._has['categories']) {
            this.getCategories()
            return 'loading...'
        }
        return (
            this._categories[category_id]
            ? this._categories[category_id].name
            : 'N/A'
        )
    }

    getMethods () {
        return this.get('/income/methods', 'methods', 200, data => {
            data.forEach(method => {
                this._methods[method.method_id] = method
            })
            this.notify('incomes.methods')
        }, () => this._methods)
    }

    createMethod (name) {
        this.post('/income/methods', {name}, 201, data => {
            this._methods[data.method_id] = data
            this.notify('methods.created', data)
        })
    }

    getMethodName (method_id) {
        if (!this._has['methods']) {
            this.getMethods()
            return 'loading...'
        }
        return (
            this._methods[method_id]
            ? this._methods[method_id].name
            : 'N/A'
        )
    }

}

var IncomesSingleton = new Incomes()

if (process.env.NODE_ENV !== 'production') window._incomes = IncomesSingleton

export default IncomesSingleton
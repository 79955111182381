const head     = document.head || {children:[{name:'viewport',content:''}]}
const viewport = window        || {innerWidth: 320}

class View {

    setHTMLMaxHeight() {
        let html = document.getElementsByTagName('html')[0]
        html.style.maxHeight = window.innerHeight + 'px'
    }

    fontSize() { return viewport.innerWidth * .04 }

    setViewport() {
        window.addEventListener('resize', this.setHTMLMaxHeight)
        this.setHTMLMaxHeight()
        if (window.devicePixelRatio <= 1) return

        let element = Array.find(head.children, el => el.name == 'viewport')

        const initialScale = 1 / viewport.devicePixelRatio

        element.content = [
            'width=device-width',
            'initial-scale='+initialScale,
            'user-scalable=0'
        ].join(',')
    }

}

var ViewSingleton = new View()

export default ViewSingleton
import React, { Fragment } from 'react'
import { Redirect } from 'react-router'

import StaticData from '../../providers/static'

import SidebarWrapper                  from '../Sidebar'
import { LinkButton, Button }          from '../Sidebar/Buttons'
import PopSelect, { PopItem, PopLink } from '../Sidebar/PopSelect'

const currentYear = new Date().getUTCFullYear()

class Sidebar extends React.Component {
    state = {
        years: false,
    }
    render() {
        const {years} = this.state
        const {year, onSelect: selectYear, selected}  = this.props

        let exTo = '/expenses'
        let inTo = '/incomes'

        if (year) {
            exTo += `/${year}`
            inTo += `/${year}`
        }

        if (selected && selected != 'Total') {
            exTo += `/${selected}`
            inTo += `/${selected}`
        }

        return <Fragment>
            <SidebarWrapper>
                {years ?
                    <Button position="1" icon="x" onClick={() => this.setState({years: false})} />
                    : null
                }
                {!years ? <Fragment>
                    <Button position="3" icon="calendar" onClick={() => {
                        this.setState({years: !years})
                    }} />
                    <LinkButton position="2" icon="dollar-sign" to={exTo} />
                    <LinkButton position="1" icon="briefcase" to={inTo} />
                    </Fragment> : null
                }
            </SidebarWrapper>
            <PopSelect open={years} position="1">
                {StaticData.years.map((y, i) =>(
                    y == year
                    ?
                    <PopItem key={i}><b>{y}</b></PopItem>
                    :
                    <PopLink key={i} to={`/dashboard/${y}`}>
                        {y}
                    </PopLink>
                ))}
            </PopSelect>
        </Fragment>
    }
}

export default Sidebar
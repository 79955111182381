import React, { Fragment } from 'react'
import styled from 'styled-components'

const FieldLabel = styled.div`
    color: ${p => p.theme.displayField}
    font-size: .75rem;
    line-height: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
`

const FieldValue = styled.div`
    font-size: 1.2rem;
`

const DisplayField = ({title, children}) => {
    return <Fragment>
        <FieldLabel>{title}</FieldLabel>
        <FieldValue>{children}</FieldValue>
        <br />
    </Fragment>
}

export default DisplayField
import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router'
import styled from 'styled-components'

import ExpenseRow from './ExpenseRow'

const Table = styled.div`
    width: 100%;
`

export default class ExpenseTable extends Component {
    state = {selected: null}
    select(expense_id) {
        this.setState({
            selected: expense_id
        })
    }
    render() {
        const {expenses, categories, methods} = this.props
        const {selected} = this.state
        if (selected) return <Redirect push to={`/expense/${selected}/view`} />
        if (!expenses.length) return 'No expenses to show.'
        return <Table>
            {expenses.sort((a, b) => {
                return (a.date < b.date ? 1
                     : (a.date > b.date ? -1
                     : (a.original_amount < b.original_amount ? 1
                     : (a.original_amount > b.original_amount ? -1
                     : 0))))
            }).map((expense, k) => (
                <ExpenseRow
                    index={k} key={k}
                    expense={expense}
                    categories={categories}
                    methods={methods}
                    select={() => this.select(expense.expense_id)}
                />
            ))}
        </Table>
    }

}
import React from 'react'
import Styled from 'styled-components'


const Row = Styled.tr`
    background-color: inherit;
    border-bottom: .01rem solid white;
    &:last-child{
        border: none;
    }
`

export const HighlightedRow = Styled(Row)`
    background-color: gray;
`

export const RowField = Styled.td`
    padding: .25rem;
    text-align: right;

    &:nth-child(1){
        text-align: left;
    }
`

export default Row


import React, { Fragment } from 'react'
import { Redirect } from 'react-router'
import Styled from 'styled-components'

import { Get, monthDisplayName as disp, dateString } from '../../providers/utilities'
import IncomeData from '../../providers/incomes'
import StaticData from '../../providers/static'

import Listener from '../../bases/Listener'

import { Button }                      from '../Sidebar/Buttons'
import PopSelect, { PopItem, PopLink } from '../Sidebar/PopSelect'

import SidebarWrapper from '../Sidebar'
import Icon           from '../Icon'
import Form           from '../Form'
import Calendar       from '../Calendar'
import Choice         from '../Choice'
import ChoiceTree     from '../ChoiceTree'
import Title          from '../Sidebar/Title'
import EditMenu       from '../Sidebar/EditMenu'
import EditText       from '../Sidebar/EditText'

const TextTitle = Styled(Title)`
    margin-bottom: 0;
`

const emptyIncome = {
    name: "",
    date: '',
    category_id:0,
    method_id: 0,
    amount: "0.00",
}

const defaultState = {
    income: emptyIncome,
    adding: false,
    loading: false,
    months: false,
    years: false
}

class Sidebar extends React.Component {
    state = defaultState
    constructor(props) {
        super(props)
        this._refs = {}
        const path = this.props.match.params
        const [year, month] = Get('year', 'month').from(path)
        this.state.income.date = dateString(1, month, year)
    }
    event(msg, data) {
        if (msg == 'incomes') {
            this.reset()
        }
    }
    new () {
        this.setState({adding: true, income: emptyIncome})
    }
    reset(nextYear, nextMonth) {
        let newState = defaultState
        if (nextMonth) {
            newState.income.date = dateString(1, nextMonth, nextYear)
        }
        else {
            const path = this.props.match.params
            const [year, month] = Get('year', 'month').from(path)
            newState.income.date = dateString(1, month, year)
        }
        this.setState(newState)
    }
    edit({name, value}) {
        const {income} = this.state
        this.setState({income:{...income, [name]: value}})
    }
    save() {
        this.setState({loading: true}, () => {
            IncomeData.create(this.state.income)
        })
    }
    componentWillReceiveProps(nextProps) {
        const {year: nextYear, month: nextMonth} = nextProps.match.params
        const {year: thisYear, month: thisMonth} = this.props.match.params
        if (nextYear != thisYear || nextMonth != thisMonth) {
            this.reset(nextYear, nextMonth)
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevState.adding && this.state.adding) {
            this._refs.name.focus()
        }
    }
    ensureAmount(value) {
        const {income} = this.state
        if (!value) this.setState({income:{...income, amount:'0.00'}})
    }
    render() {
        const {adding, income, months, years, back} = this.state

        const path          = this.props.match.params
        const [year, month] = Get('year', 'month').from(path)

        if (back) return <Redirect to={`/dashboard/${year}`} />

        const methods        = IncomeData.getMethods()
        const categories     = IncomeData.getCategories()
        const method_items   = {}
        const category_items = {}

        if (methods) {
            Object.keys(methods).forEach(method_id => {
                method_items[method_id] = methods[method_id].name
            })
        }

        if (categories) {
            Object.keys(categories).forEach(category_id => {
                category_items[category_id] = categories[category_id].name
            })
        }

        const {loading} = this.state

        return <Fragment>
            <SidebarWrapper>
                {!loading && adding ?
                    <Fragment>
                        <Button position="2" icon="check" onClick={() => this.save()} />
                        <Button position="1" icon="x" onClick={() => this.reset()} />
                    </Fragment>
                    : loading ?
                    <Button position="1" icon="spinner9" />
                    : <Fragment>
                        <Button position="3" icon="calendar" onClick={() => {
                            this.setState({months: !months, years: false})
                        }} />
                        <Button position="2" icon="plus" onClick={() => this.new()} />
                        <Button position="1" icon="arrow-left" onClick={() => {
                            this.setState({back: true})
                        }} />
                      </Fragment>
                }
            </SidebarWrapper>
            {!loading && months ?
                <PopSelect position="2" open={months||years}>
                    {!years ?
                        <PopItem key={17} onClick={() => {
                            this.setState({years: true})
                        }}><Icon i="circle-left" /></PopItem>
                        : null
                    }
                    {!years ? StaticData.months.map((m, i) => (
                        m === month
                        ?
                        <PopItem key={i} onClick={() => this.reset()}>
                            <b>{disp(m)}</b>
                        </PopItem>
                        :
                        <PopLink key={i} to={`/incomes/${year}/${m}`}>
                            {disp(m)}
                        </PopLink>
                    )) : StaticData.years.map((y, i) => (
                        y === year
                        ?
                        <PopItem key={i} onClick={() => this.reset()}>
                            <b>{y}</b>
                        </PopItem>
                        :
                        <PopLink key={i} to={`/incomes/${y}/${month}`}>
                            {y}
                        </PopLink>
                    ))}
                </PopSelect>
                : null
            }
            {!loading && adding ?
                <EditMenu>
                    <Form send={() => this.save()}>
                        <TextTitle>Name</TextTitle>
                        <EditText name="name"
                            ref={x => {this._refs.name = x}}
                            id="name"
                            type="text"
                            value={income.name || ''}
                            onChange={e => this.edit(e.target)}
                        />
                        <TextTitle>Amount</TextTitle>
                        <EditText name="amount"
                            id="amount"
                            step="0.01"
                            type="number"
                            value={income.hasOwnProperty('amount') ? income.amount : '0.00'}
                            onChange={e => this.edit(e.target)}
                            onBlur={e => this.ensureAmount(e.target.value)}
                        />
                        <TextTitle>Date</TextTitle>
                        <Calendar
                            year={year}
                            month={month}
                            current={income.date}
                            onChoose={(e,s) => this.edit({name: 'date', value:e})}
                        />
                        <TextTitle>Payment Method</TextTitle>
                        <Choice
                            name="method_id"
                            current={income.method_id}
                            items={method_items}
                            onChoose={e => this.edit({name: 'method_id', value: e})}
                        />
                        <TextTitle>Category</TextTitle>
                        <Choice name="category_tree"
                            current={income.category_id}
                            items={category_items}
                            onChoose={e => this.edit({name: 'category_id', value: e})}
                        />
                    </Form>
                </EditMenu>
                : null}
        </Fragment>
    }
}

export default Listener(Sidebar, ['incomes'])
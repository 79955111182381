import LightTheme from '../styles/themes/light'
import DarkTheme from '../styles/themes/dark'

const defaultTheme = 'dark'

const themeMap = {
    light: LightTheme,
    dark: DarkTheme
}

class Theme {

    constructor(){
        this.theme = defaultTheme
        this.style = themeMap[defaultTheme]
    }

    change(theme) {
        theme = theme || defaultTheme
        if (theme != this.theme) {
            this.theme = theme
            this.style = themeMap[theme]
            this.setTheme()
        }
    }

    getTheme() { return this.style }

    apply() {
        const html = document.documentElement
        const body = document.body

        html.style.backgroundColor = this.style.background
        body.style.backgroundColor = this.style.background
        body.style.color = this.style.primary
    }

}

var ThemeSingleton = new Theme()

export default ThemeSingleton
import React, { Component } from 'react'
import { Redirect } from 'react-router'
import Styled from 'styled-components'

import Listener from '../bases/Listener'

import Logo from '../images/x-180.png'

import App from '../providers/app'

const LoadingWrapper = Styled.div`
    width: 100vw;
    height: 100vh;
    line-height: 100vh;
    background-image: url(${Logo});
    background-repeat: no-repeat;
    background-position: center 90px;
    background-size: 180px;
`

class LoadingScreen extends Component {

    state = {
        authorized: App.userAuthorized()
    }
    event(msg, data) {
        if (msg === 'login.user') this.setState(data)
    }
    render() {
        const {authorized} = this.state
        if (authorized) return <Redirect to="/dashboard" />
        if (!authorized && typeof authorized == 'boolean') return <Redirect to="/login" />
        return <LoadingWrapper />
    }

}

export default Listener(LoadingScreen, 'login')

import React from 'react'
import Styled from 'styled-components'

const Submit = Styled.input`
    display: none;
`


export default function Form({send, children}) {
    return <form autoComplete="off" onSubmit={(e) => {
        e.preventDefault()
        send(e)
        return false
    }}>{children}<Submit type="submit" /></form>
}
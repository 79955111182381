import React, { Component, Fragment } from 'react'
import { HashRouter as Router, Route, DefaultRoute } from 'react-router-dom'

import Styled, { ThemeProvider } from 'styled-components'

import Status           from './pages/Status'
import Login            from './pages/Login'
import LoadingScreen    from './pages/Loading'
import Dashboard        from './pages/Dashboard'
import Expense          from './pages/ExpenseView'
import Expenses         from './pages/Expenses'
import Payback          from './pages/PaybackView'
import Income           from './pages/IncomeView'
import Incomes          from './pages/Incomes'
import LoginForm        from './components/Login/LoginForm'
import ExpensesSidebar  from './components/Expenses/Sidebar'
import ExpenseSidebar   from './components/Expenses/ViewSidebar'
import PaybackSidebar   from './components/Paybacks/ViewSidebar'
import IncomeSidebar    from './components/Incomes/ViewSidebar'
import IncomesSidebar   from './components/Incomes/Sidebar'

import App from './providers/app'

import Theme from './providers/theme'

const Dummy = function() { return <Fragment>not implemented</Fragment> }
const Paybacks    = Dummy,
      Payers      = Dummy,
      Methods     = Dummy,
      Categories  = Dummy

export default class Main extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userAuthorized: false
        }
    }

    componentWillMount() {
        this._ = App.listen('login', this.event.bind(this))
    }

    componentDidMount() {
        let userAuthorized = App.userAuthorized()
        if (userAuthorized) this.setState({userAuthorized})
    }

    componentWillUnmount() {
        App.ignore(this._)
    }

    event(msg, data) {
        console.log('app got', msg, data)
        if (msg == 'login.user') {
            this.setState({userAuthorized: data.authorized}, () => {
                if (!data.authorized && window.location.hash !== '/') {
                    window.location.hash = '/'
                }
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.userAuthorized != nextState.userAuthorized) return true
        return false
    }

    render() {
        const {userAuthorized: ready} = this.state
        return (
            <Router>
            <ThemeProvider theme={Theme.getTheme()}>
            <Fragment>
                <Route exact path="/" component={LoadingScreen} />
                <Route exact path="/login" component={Login} />
                {ready && <Fragment>
                    <Route path="/dashboard/:year?/:month?" component={Dashboard} />
                    <Route path="/expense/:expense_id/view"
                           component={Expense} />
                    <Route path="/expense/:expense_id/payback/:payback_id"
                           component={Payback} />
                    <Route path="/expenses/:year?/:month?"
                           component={Expenses} />
                    <Route path="/income/:income_id/view"
                           component={Income} />
                    <Route path="/incomes/:year?/:month?"
                           component={Incomes} />
                    <Route path="/:type/methods"
                           component={Methods} />
                    <Route path="/:type/categories"
                           component={Categories} />
                    <Route path="/payers"
                           component={Payers} />
                    <Route path="/paybacks/:year"
                           component={Paybacks}/>
                </Fragment>}
                <Route path="/expenses/:year?/:month?"
                       component={ExpensesSidebar} />
                <Route path="/expense/:expense_id/view"
                       component={ExpenseSidebar} />
                <Route path="/expense/:expense_id/payback/:payback_id"
                           component={PaybackSidebar} />
                <Route path="/incomes/:year?/:month?"
                       component={IncomesSidebar} />
                <Route path="/income/:income_id/view"
                       component={IncomeSidebar} />
            </Fragment>
            </ThemeProvider>
            </Router>
        )
    }
}
export default {
    primary: '#D0D0D0',
    background: '#2D2D2D',
    highlight: '#CCC',
    chartLabel: '#222',
    lighter: '#FFF',
    popItem: '#D0D0D0',
    popItemHighlight: '#DDD',
    shadow: 'rgba(40,40,40,.4)',
    dash: {
        pos: '#C3E29D',
        neg: '#FF6E83',
    },
    calendar: {
        tile: '#262626',
        pick: '#C3E88D'
    }
}
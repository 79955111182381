import React from 'react'
import { Link } from 'react-router-dom'
import Styled from 'styled-components'

import Icon from '../Icon'

const buttonSize = 2.5

const LinkButtonBase = Styled(Link)`
    position: absolute;
    right: ${p => (parseInt(p.position) * 3.3) - 2.8}rem;
    bottom: 1.25rem;
    font-size: 1.5rem;
    width: ${buttonSize}rem;
    height: ${buttonSize}rem;
    border-radius: 100%;
    padding: .37rem;
    color: ${p => p.theme.primary};
    text-decoration: none;
    border: .13rem solid ${p => p.theme.primary};
`

const ButtonBase = Styled.div`
    position: absolute;
    right: ${props => (parseInt(props.position) * 3.3) - 2.8}rem;
    bottom: 1.25rem;
    font-size: 1.5rem;
    width: ${buttonSize}rem;
    height: ${buttonSize}rem;
    border-radius: 100%;
    padding: .37rem;
    border: .13rem solid ${p => p.theme.primary};
`
export const Button = (props) => {
    return <ButtonBase {...props}><Icon i={props.icon} /></ButtonBase>
}

export const LinkButton = props => {
    return <LinkButtonBase {...props}><Icon i={props.icon} /></LinkButtonBase>
}

export default Button
import React, { Component, Fragment } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-labels'

import { money } from '../../providers/utilities'

import View  from '../../providers/view'
import Theme from '../../providers/theme'

// TODO: import these from the theme
function backgroundColors(num) {

    return [
//        '#CFD8DC',
//        '#B0BEC5',
//        '#90A4AE',
//        '#78909C',
//        '#607D8B',
//        '#546E7A',
//        '#455A64'

        '#4dc9f6',
        '#f67019',
        '#f53794',
        '#537bc4',
        '#acc236',
        '#166a8f',
        '#00a950',
        '#58595b',
        '#8549ba'
    ].slice(0, num)
}

export default class PieChart extends Component {
    constructor(props) {
        super(props)
        this.chartCanvas = null
        this.chartInstance = null
    }
    componentDidMount() {
        this.chartInstance = this.makeChart()
    }
    componentDidUpdate() {
        this.chartInstance.destroy()
        this.chartInstance = this.makeChart()
    }
    makeChart() {
        const {data: sourceData, onSelect} = this.props
        const dataIDs = Object.keys(sourceData)
        let labels = dataIDs.map(id => sourceData[id].label)
        let total  = 0
        let data   = dataIDs.map(id => {
            total += sourceData[id].value
            return sourceData[id].value
        })
        let backgroundColor = backgroundColors(dataIDs.length)
        let fontSize        = View.fontSize() * .8
        return new Chart(this.chartCanvas.getContext('2d'), {
            type: 'pie',
            data: {
                labels: labels,
                datasets: [{
                    data,
                    backgroundColor,
                }]
            },
            options: {
                defaultFontSize: fontSize,
                legend: {
                    labels: {
                        usePointStyle: true,
                        fontSize,
                        fontColor: Theme.getTheme().primary
                    },
                    onClick: function(e, target) {
                        onSelect(target.text, dataIDs[target.index])
                    },
                    fullWidth: true,
                    display: true,
                    //align: 'center',
                    position: 'bottom'
                },
                elements: {
                    arc: {
                        borderColor: Theme.getTheme().chartLabel
                    }
                },
                plugins: {
                    labels: {
                        fontColor: Theme.getTheme().chartLabel
                    }
                },
                tooltips: {
                    bodyFontSize: fontSize,
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let label = data.labels[tooltipItem.index] || 'undefined'

                            if (label) {
                                label += ': '
                            }
                            let amount = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                            label += money(amount)
                            let pct = Math.round((amount / total) * 10000) / 100
                            return [label, pct + '% of Total']
                        },
                    }
                },
                onClick: function(e, targets) {
                    if (!targets.length) return
                    let chartElement = targets[0]
                    onSelect(chartElement._model.label, dataIDs[chartElement._index])
                }
            }
        })
    }
    render() {
        return <Fragment>
            <canvas
                ref={me => this.chartCanvas = me}
                width="100%"
                height="100%"
            ></canvas>
        </Fragment>
    }
}
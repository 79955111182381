import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
    position: absolute;
    font-weight: 500;
    top: .25rem;
    right: .25rem;
    margin: .25rem;
`

export default function Total(props) {
    return <Title>{props.children}</Title>
}
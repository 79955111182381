import React, { Fragment } from 'react'
import { Redirect } from 'react-router'

import LoginForm from '../components/Login/LoginForm'

import App from '../providers/app'
import Listener from '../bases/Listener'

class Login extends React.Component {

    constructor() {
        super()
        this.state = {
            errorMessage: null,
            fb: true,
            loading: false,
            authorized: false
        }
    }

    attemptLogin = x => this.setState({loading: true}, () => App.loginEmail(x))

    event(msg, data) {
        console.log('login event', msg, data)
        if (msg == 'login.user') {
            this.setState({
                loading: false,
                errorMessage: data.error,
                authorized: data.authorized,
            })
        }
        else if (msg == 'login.fb') {
            let newState = {fb: false, loading: false}
            if (data.error) newState.errorMessage = data.error
            this.setState(newState)
        }
    }

    shouldComponentUpdate($_, ns) {
        let cs = this.state
        let diffs = ['fb', 'errorMessage', 'loading', 'authorized']
        return diffs.filter(x => (cs[x] !== ns[x])).length ? true : false
    }

    render() {
        let {errorMessage, loading, fb, authorized} = this.state
        if (authorized) return <Redirect to="/dashboard" />
        return <Fragment>
            {errorMessage &&
                <p className="error">{errorMessage}</p>
            }
            <LoginForm loading={loading} action={this.attemptLogin} />
            {fb ||
                <button onClick={x => App.fbLogin()}>Continue With FaceBook</button>
            }
        </Fragment>
    }

}

export default Listener(Login, ['login'])
import React, { Fragment } from 'react'
import { Redirect } from 'react-router'

import Styled from 'styled-components'

import Container from '../elements/Container'

import DisplayField from '../components/DisplayField'
import Paybacks from '../components/Expenses/Paybacks'

import ExpenseData from '../providers/expenses'
import Listener from '../bases/Listener'

const ExpensePage = Styled.div`
    padding: 1rem;
    position: relative;
    height: 100%;
    widght: 100%;
`
class ExpenseView extends React.Component {
    state = {
        pb: null
    }

    render() {
        const {expense_id} = this.props.match.params
        const expense      = ExpenseData.info(expense_id)
        if (!expense) return 'loading...'
        const method       = ExpenseData.getMethod(expense.method_id)
        const category     = ExpenseData.getCategoryName(expense.category_tree)
        let paybacks = false
        const showOriginal = expense.amount < expense.original_amount
        if (showOriginal) {
            if (this.state.pb != null) {
                return <Redirect push to={
                    `/expense/${expense_id}/payback/${this.state.pb}`
                } />
            }
            paybacks = ExpenseData.getPaybacks(expense.expense_id)
            if (!!paybacks) {
                paybacks = <Paybacks list={paybacks} onSelect={id => this.setState({pb: id})} />
            }
        }
        return <Container><ExpensePage>
            <DisplayField title="Name">{expense.name}</DisplayField>
            <DisplayField title="Payment Method">{method}</DisplayField>
            <DisplayField title="Category">{category}</DisplayField>
            <DisplayField title="Date">{expense.date}</DisplayField>
            <DisplayField title="Amount">{expense.amount_money}</DisplayField>
            {showOriginal ?
                <DisplayField title="Original Amount">
                    {expense.original_money}
                </DisplayField>
                : null
            }
            {!!paybacks ?
                <DisplayField title="Paybacks">{paybacks}</DisplayField>
                : null
            }
        </ExpensePage></Container>
    }
}

export default Listener(ExpenseView, ['expenses'])
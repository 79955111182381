import React, { Fragment } from 'react'
import Styled from 'styled-components'

import Icon from './Icon'

export const Wrapper = Styled.div`
    margin: 1em;
    max-width: 75vw;
    min-height: 2rem;
    max-height: 40vh;
    overflow: hidden;
    overflow-y: scroll;
`

export const Item = Styled.div`
    height: 2rem;
    line-height: 2rem;
    font-size: 1rem;
    cursor: pointer;
    &:hover{
        background-color: ${p=> p.theme.primary};
        color: ${p=> p.theme.background};
    }
`

export const Title = Styled.div`
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 1rem;
    padding-left: 1rem;
`

export default function Choice({current, items, onChoose, title, createNew}){
    return  <Fragment>
        {title ? <Title>{title}</Title> : null}
        <Wrapper>
            {Object.keys(items).map(item_id => {
                return <Item key={item_id} onClick={
                    () => onChoose(item_id)
                }>{current == item_id ? <Icon small i="checkmark" /> : ''} {items[item_id]}</Item>
            })}
            <Item onClick={() => createNew()}>
                <Icon small i="plus" /> New...
            </Item>
        </Wrapper>
    </Fragment>
}
import React from 'react'

import { money }   from '../../providers/utilities'
import ExpenseData from '../../providers/expenses'

import Table                      from './Table'
import { HeaderRow, HeaderField } from './TableHeader'
import Row, { RowField }          from './Row'

const byAmount = (a, b) => (
    a.amount < b.amount ? 1 : a.amount > b.amount ? -1 : 0
)

const expenseToRow = (exp, i) => (
    <Row key={i}>
        <RowField>{exp.name}</RowField>
        <RowField>{exp.category}</RowField>
        <RowField>{exp.date.substring(5)}</RowField>
        <RowField>{money(exp.amount)}</RowField>
    </Row>
)

export default function TopExpenses({from}) {
    if (!from.length) return null;

    let rows = from.sort(byAmount).map(expenseToRow);

    return <Table>
        <thead>
            <HeaderRow>
                <HeaderField>Name</HeaderField>
                <HeaderField>Category</HeaderField>
                <HeaderField>Date</HeaderField>
                <HeaderField>Amount</HeaderField>
            </HeaderRow>
        </thead>
        <tbody>
            {rows}
        </tbody>
    </Table>

}

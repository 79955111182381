import React, { Fragment } from 'react'
import { Redirect } from 'react-router'

import Styled from 'styled-components'

import Container from '../elements/Container'

import DisplayField from '../components/DisplayField'

import ExpenseData from '../providers/expenses'
import { money } from '../providers/utilities'
import Listener from '../bases/Listener'

const PaybackPage = Styled.div`
    padding: 1rem;
    position: relative;
    height: 100%;
    width: 100%;
`

class PaybackView extends React.Component {
    state = {
        back: false
    }

    render() {
        const {payback_id, expense_id} = this.props.match.params

        const payback = ExpenseData.getPayback(expense_id, payback_id)

        if (!payback) return 'loading...'

        const payer_name = ExpenseData.getPayerName(payback.payer_id)
        const method     = ExpenseData.getMethod(payback.method_id)

        return <Container><PaybackPage>
            <DisplayField title="Payer">{payer_name}</DisplayField>
            <DisplayField title="Amount">{money(payback.amount)}</DisplayField>
            <DisplayField title="Date Paid">{payback.paid}</DisplayField>
            <DisplayField title="Payment Method">{method}</DisplayField>
        </PaybackPage></Container>
    }
}

export default Listener(PaybackView, ['expenses'])
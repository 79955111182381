import React from 'react'
import styled from 'styled-components'

import ExpenseData from '../../../providers/expenses'

const pad = '.4rem'

const Payback = styled.div`
    width: 100%;
    border: 1px solid ${p => p.theme.primary};
    display: block;
    position: relative;
    height: 3rem;
    border-radius: .75rem;
    margin-bottom: .5rem;
    font-size: .8rem;
`

const PaybackField = styled.div`
    position: absolute;
`

const PaybackName = styled(PaybackField)`
    top: ${pad};
    left: ${pad};
    font-weight: 500;
`

const PaybackDate = styled(PaybackField)`
    top: ${pad};
    right: ${pad};
`

const PaybackMethod = styled(PaybackField)`
    bottom: ${pad};
    left: ${pad};
`

const PaybackAmount = styled(PaybackField)`
    bottom: ${pad};
    right: ${pad};
    font-size: 1rem;
    font-weight: 600;
`

const PaybackRow = ({payback, onClick}) => {
    return <Payback onClick={onClick}>
        <PaybackName>
            {ExpenseData.getPayerName(payback.payer_id)}
        </PaybackName>
        <PaybackDate>
            {payback.paid}
        </PaybackDate>
        <PaybackMethod>
            {ExpenseData.getMethod(payback.method_id)}
        </PaybackMethod>
        <PaybackAmount>
            {payback.amount.toFixed(2)}
        </PaybackAmount>
    </Payback>
}

export default PaybackRow
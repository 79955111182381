import React, { Fragment } from 'react'
import Styled from 'styled-components'

import Container from '../elements/Container'

import DisplayField from '../components/DisplayField'
import Paybacks from '../components/Incomes/Paybacks'

import IncomeData from '../providers/incomes'
import Listener from '../bases/Listener'

const IncomePage = Styled.div`
    padding: 1rem;
    position: relative;
    height: 100%;
    widght: 100%;
`
class Income extends React.Component {

    render() {
        const {income_id}  = this.props.match.params
        const income       = IncomeData.info(income_id)
        if (!income) return 'loading...'
        const method       = IncomeData.getMethodName(income.method_id)
        const category     = IncomeData.getCategoryName(income.category_id)
        return <Container><IncomePage>
            <DisplayField title="Name">{income.name}</DisplayField>
            <DisplayField title="Payment Method">{method}</DisplayField>
            <DisplayField title="Category">{category}</DisplayField>
            <DisplayField title="Date">{income.date}</DisplayField>
            <DisplayField title="Amount">{income.amount_money}</DisplayField>
        </IncomePage></Container>
    }

}

export default Listener(Income, ['incomes'])
import axios from 'axios'

const PRODUCTION_BASE = 'https://api.expectre.net'

const AXIOS_CONFIG =  {
    validateStatus: false,
    withCredentials: true,
    headers: {
        'X-Expectre-Client': 'web',
    }
}

if (process.env.NODE_ENV === 'production') {
    AXIOS_CONFIG.baseURL = PRODUCTION_BASE
}

const API = axios.create(AXIOS_CONFIG)
export default API
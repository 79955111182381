import React, { Fragment } from 'react'
import { Redirect } from 'react-router'
import Styled from 'styled-components'

import { Get, monthDisplayName as disp, dateString } from '../../providers/utilities'
import ExpenseData from '../../providers/expenses'
import StaticData  from '../../providers/static'

import Listener from '../../bases/Listener'

import { EditButton, CancelButton, Button } from '../Sidebar/Buttons'
import PopSelect, { PopItem, PopLink }      from '../Sidebar/PopSelect'

import SidebarWrapper from '../Sidebar'
import Icon           from '../Icon'
import Form           from '../Form'
import Calendar       from '../Calendar'
import Choice         from '../Choice'
import ChoiceTree     from '../ChoiceTree'
import Title          from '../Sidebar/Title'
import EditMenu       from '../Sidebar/EditMenu'
import EditText       from '../Sidebar/EditText'

const TextTitle = Styled(Title)`
    margin-bottom: 0;
`

const emptyExpense = {
    name: "",
    date: '',
    category_tree:[],
    method_id: 0,
    amount: "0.00",
}

const emptyMethod   = { name: "" }
const emptyCategory = { name: "" }

const defaultState = {
    expense: emptyExpense,
    adding: false,
    loading: false,
    months: false,
    years: false,
    addingMethod: false,
    addingCategory: false,
    method: emptyMethod,
    category: emptyCategory,
}

class Sidebar extends React.Component{
    state = defaultState
    constructor(props) {
        super(props)
        this._refs = {}
        const path              = this.props.match.params
        const [year, month]     = Get('year', 'month').from(path)
        this.state.expense.date = dateString(1, month, year)
    }
    event(msg, data) {
        if (msg == 'expenses') this.reset()
    }
    new () {
        this.setState({adding: true, expense: emptyExpense})
    }
    reset(nextYear, nextMonth) {
        let newState = defaultState
        if (nextMonth) {
            newState.expense.date = dateString(1, nextMonth, nextYear)
        }
        else {
            const path              = this.props.match.params
            const [year, month]     = Get('year', 'month').from(path)
            newState.expense.date = dateString(1, month, year)
        }
        this.setState(newState)
    }
    edit({name, value}) {
        const {expense} = this.state
        this.setState({expense:{...expense, [name]: value}})
    }
    save() {
        this.setState({loading: true}, () => {
            if (this.state.adding) {
                ExpenseData.createExpense(this.state.expense)
            }
            else if (this.state.addingMethod) {

            }
            else if (this.state.addingCategory) {

            }
        })
    }
    componentWillReceiveProps(nextProps) {
         const {year: nextYear, month: nextMonth} = nextProps.match.params
         const {year: thisYear, month: thisMonth} = this.props.match.params
         if (nextYear != thisYear || nextMonth != thisMonth) {
             this.reset(nextYear, nextMonth)
         }
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevState.adding && this.state.adding) {
            this._refs.name.focus()
        }
    }
    ensureAmount(value) {
        const {expense} = this.state
        if (!value) this.setState({expense:{...expense, amount:'0.00'}})
    }
    render() {
        const {expense, months, years, back, method, category} = this.state
        const {adding, addingMethod, addingCategory} = this.state

        const path          = this.props.match.params
        const [year, month] = Get('year', 'month').from(path)

        if (back) return <Redirect to={`/dashboard/${year}`} />

        const methods      = ExpenseData.getMethods()
        const categories   = ExpenseData.getCategories()
        const method_items = {}

        if (methods) {
            Object.keys(methods).forEach(method_id => {
                method_items[method_id] = methods[method_id].name
            })
        }

        const {loading} = this.state

        return <Fragment>
            <SidebarWrapper>
                { loading ?
                    <Icon i="spinner9" spin />
                : addingMethod || addingCategory ?
                    <Fragment>
                        {addingMethod ?
                            <Button position="2" icon="check" onClick={() => this.add()} />
                        :
                            <Button position="2" icon="check" onClick={() => this.add()} />
                        }
                        <Button position="1" icon="x" onClick={() => this.setState({
                            addingMethod: false,
                            addingCategory: false,
                        })} />
                    </Fragment>
                : adding ?
                    <Fragment>
                        <Button position="2" icon="check" onClick={() => this.save()} />
                        <Button position="1" icon="x" onClick={() => this.reset()} />
                    </Fragment>
                :
                    <Fragment>
                        <Button position="3" icon="calendar" onClick={() => {
                            this.setState({months: !months, years: false})
                        }} />
                        <Button position="2" icon="plus" onClick={() => this.new()} />
                        <Button position="1" icon="arrow-left" onClick={() => {
                            this.setState({back: true})
                        }} />
                    </Fragment>
                }
            </SidebarWrapper>
            {!loading && months ?
                <PopSelect position="2" open={months||years}>
                    {!years ?
                        <PopItem key={20} onClick={() => {
                            this.setState({years: true})
                        }}><Icon i="circle-left" /></PopItem>
                     : null
                    }
                    {!years ? ExpenseData.months.map((m, i) => (
                        m === month
                        ?
                        <PopItem key={i} onClick={() => this.reset()}>
                            <b>{disp(m)}</b>
                        </PopItem>
                        :
                        <PopLink key={i} to={`/expenses/${year}/${m}`}>
                            {disp(m)}
                        </PopLink>
                    )) : StaticData.years.map((y, i) => (
                        y == year
                        ?
                        <PopItem key={i} onClick={() => this.reset()}>
                            <b>{y}</b>
                        </PopItem>
                        :
                        <PopLink key={i} to={`/expenses/${y}/${month}`}>
                            {y}
                        </PopLink>
                    ))}
                </PopSelect>
            : !loading && addingMethod ?
                <EditMenu>
                    <Form send={() => this.createMethod()}>
                        <TextTitle>Name</TextTitle>
                        <EditText name="method_name"
                            ref={x => {this._refs.methodName = x}}
                            id="method_name"
                            type="text"
                            value={method.name}
                        />
                    </Form>
                </EditMenu>
            : !loading && addingCategory ?
                <EditMenu>
                    <Form send={() => this.createCategory()}>
                        <TextTitle>Name</TextTitle>
                        <EditText name="category_name"
                            ref={x => {this._refs.categoryName = x}}
                            id="category_name"
                            type="text"
                            value={category.name}
                        />
                    </Form>
                </EditMenu>
            : !loading && adding ?
                <EditMenu>
                    <Form send={() => this.save()}>
                        <TextTitle>Name</TextTitle>
                        <EditText name="name"
                            ref={x => {this._refs.name = x}}
                            id="name"
                            type="text"
                            value={expense.name || ''}
                            onChange={e => this.edit(e.target)}
                        />
                        <TextTitle>Amount</TextTitle>
                        <EditText name="amount"
                            id="amount"
                            step="0.01"
                            type="number"
                            value={expense.hasOwnProperty('amount') ? expense.amount : '0.00'}
                            onChange={e => this.edit(e.target)}
                            onBlur={e => this.ensureAmount(e.target.value)}
                        />
                        <TextTitle>Date</TextTitle>
                        <Calendar
                            year={year}
                            month={month}
                            current={expense.date}
                            onChoose={(e, s) => this.edit({name:'date', value:e})}
                        />
                        <TextTitle>Payment Method</TextTitle>
                        <Choice name="method_id"
                            current={expense.method_id}
                            items={method_items}
                            onChoose={e => this.edit({name: 'method_id', value: e})}
                            createNew={() => this.setState({addingMethod: true})}
                        />
                        <TextTitle>Category</TextTitle>
                        <ChoiceTree name="category_tree"
                            current={expense.category_tree || []}
                            items={categories}
                            onChoose={e => this.edit({name: 'category_tree', value: e})}
                            createNew={() => this.setState({addingCategory: true})}
                        />
                    </Form>
                </EditMenu>
                : null}
        </Fragment>
    }
}

export default Listener(Sidebar, ['expenses'])
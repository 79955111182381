import Notifier from './notifier'

class Keyboard extends Notifier {

    constructor() {
        super()
        window.addEventListener('keydown', this.recv.bind(this))
    }

    recv(e) {
        if(e.target.getAttribute('data-stop') == 'keyboard')
            e.preventDefault() && e.stopPropagation()
        this.notify('key', e)
    }

}

let KeyboardSingleton = new Keyboard()
if (process.env.NODE_ENV !== 'production') {
    window._keyboard = KeyboardSingleton
}
export default KeyboardSingleton
import React, { Fragment } from 'react'

import Listener from '../bases/Listener'
import Container from '../elements/Container'

import ExpenseData          from '../providers/expenses'
import { Get, monthDisplayName } from '../providers/utilities'

import ExpenseTable from '../components/Expenses/ExpenseTable'
import Header       from '../components/Header'
import Total        from '../components/Total'

const Load = () => <b>Loading!!!</b>

class Expenses extends React.Component {

    ym = () => Get('year','month').from(this.props.match.params)

    render() {
        let [year, month] = this.ym()
        month = month.substring(0,3)
        let expenses      = ExpenseData.getMonth(year, month)
        let expenseTotal  = ExpenseData.getTotal(year, month)
        let categories    = ExpenseData.getCategories()
        let methods       = ExpenseData.getMethods()
        return <Fragment><Container>
            {!expenses ? <Load />
                       : <Fragment>
                           <Header>{monthDisplayName(month)} {year}</Header>
                           <Total>{expenseTotal}</Total>
                           <ExpenseTable expenses={expenses}
                                         methods={methods}
                                         categories={categories} />
                         </Fragment>
            }
        </Container></Fragment>
    }

}

export default Listener(Expenses, ['expenses'])
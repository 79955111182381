import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import Report from '../providers/report'

import Container from '../elements/Container'

import DashboardSidebar from '../components/Dashboard/Sidebar'
import PieChart         from '../components/Dashboard/PieChart'
import MonthList        from '../components/Dashboard/MonthList'
import TopExpenses      from '../components/Dashboard/TopExpenses'
import LoadingScreen    from '../components/LoadingScreen'
import Header           from '../components/Header'

const defaultState = {
    data: null,
    lastYear: null,
    year: new Date().getUTCFullYear(),
    mons: [],
    et: null,
    it: null,
    dt: null,
    selected: 'Total',
    loading: true,
    top: [],
}

export default class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = defaultState
        this.state.year = props.match.params.year || defaultState.year
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.year != this.state.year) {
            this.initialize(nextProps.match.params.year)
        }
    }

    componentDidMount() {
        this.initialize(this.state.year)
    }

    initialize(year) {
        this.setState({
            data: null,
            dt: null,
            year,
            loading: true,
        }, () => {
            this.loadMonths()
        });
    }

    loadMonths() {
        Report.getCalendarYear(this.state.year).then(summary => {
            this.setState({
                mons: summary.rows,
                et: summary.expenseTotal,
                it: summary.incomeTotal,
                dt: summary.differenceTotal,
                loading: this.state.data == null,
            }, this.reload)
        })
    }

    reload(month = 'Total', category_id = 0) {
        if (month == 'Total') month = null
        let year = this.state.year

        Report.getCategoryTotals({month, category_id, year}).then(compiled => {
            this.setState({
                top: compiled[2],
                data: compiled[0],
                selected: month || 'Total',
                loading: this.state.dt == null,
            })
        })
    }

    pick(label, category_id) {
        this.reload(this.state.selected, category_id)
    }

    selectMonth(selected) {
        this.reload(selected, 0)
    }

    render() {
        let {data, mons, et, it, dt, selected, loading, year, top} = this.state
        if (loading) return <LoadingScreen msg="Loading summary..." />
        return <Fragment>
            <Container>
            <Header>Overview of {year}</Header>
            <MonthList
                rows={[...mons]}
                totals={{et, it, dt}}
                selected={selected}
                onSelect={month => this.selectMonth(month)}
            />
            <PieChart data={data} onSelect={(lbl, k) => this.pick(lbl, k)} />
            {top ? <TopExpenses from={top} /> : null}
            </Container>
            <DashboardSidebar year={year} onSelect={y => this.initialize(y)} selected={selected} />
        </Fragment>
    }

}
import React, { Fragment } from 'react'

import Container from '../elements/Container'

import IncomeData   from '../providers/incomes'
import { Get, monthDisplayName } from '../providers/utilities'

import IncomeTable  from '../components/Incomes/IncomeTable'
import Listener     from '../bases/Listener'
import Header from '../components/Header'
import Total from '../components/Total'

const Load = () => <b>Loading!!!</b>

class Incomes extends React.Component {

    ym = () => Get('year','month').from(this.props.match.params)

    render() {
        let [year, month] = this.ym()
        let incomes       = IncomeData.getMonth(year, month)
        let incomesTotal  = IncomeData.getTotal(year, month)
        return <Container>
            {!incomes ? <Load />
                      : <Fragment>
                            <Header>{monthDisplayName(month.substring(0,3))} {year}</Header>
                            <Total>{incomesTotal}</Total>
                            <IncomeTable incomes={incomes} />
                        </Fragment>
            }
        </Container>
    }

}

export default Listener(Incomes, ['incomes'])
import API      from './api'
import Notifier from './notifier'
import axios    from 'axios'

import { checkLogin as fbCheck, logIn as fbLogin } from './fb'

/**
 * Hide the data from the consumers
 */
let data = {}

/**
 * Core application data layer
 */
class App extends Notifier {

    constructor() {
        super()
    }

    init() {
        this.api = API
        window.onerror = function(msg, source, lineno, colno, error) {
            if (error) msg = error.stack
            this.logg(msg)
        }.bind(this)
        data.connected = false
        data.logged_in = null
        data.facebook  = false
        this.connect()
        this.checkFB()
        this.checkAuth()
        this.notify('app.constructed')
        window._initialized = true
    }

    logg(msg) {
        if (process.env.NODE_ENV !== 'production') {
            this.api.get("/log?msg="+encodeURIComponent(msg))
        }
        console.log(msg)
    }

    fbLoggedIn()     { return data.facebook      }
    isConnected()    { return data.connected     }
    userAuthorized() { return data.logged_in     }
    userProfile()    { return data.profile || {} }
    fbLogin()        { fbLogin(this.handleFB)    }

    handleError(thing) {
        this.logg('handleError called')
        if (thing && thing.status && !thing.status.toString().match(/2\d{2}/)) {
            if (thing.status == 403) this.checkAuth()
            if (thing.status == 404) window.location.hash = '/'
        }
        else{
            alert('an unknown error occured. check them stack traces boi:' + thing.toString())
        }
    }

    connect() {
        this.api.get('/health', {timeout: 1000, withCredentials: false})
        .then(res => {
            let connected = false
            if (res.status == 200) {
                if (res.data.status && res.data.status == 'alive') {
                    console.log('Expectre API is alive and well.')
                    data.connected = connected = true
                }
                else {
                    connected = false
                }
            }
            this.notify('api.connected', connected)
        })
        .catch(err => this.notify('api.connected', false))
    }

    checkAuth() {
        this.api.get('/user/profile').then(res => {
            let authorized = false
            if (res.status == 200) {
                data.logged_in = true
                authorized = true
                data.profile = res.data
                this.notify('user.profile', data.profile)
            }
            else {
                // it's possible they got logged out
                data.logged_in = false
                authorized = false
            }
            this.notify('login.user', {authorized})
            this.logg('checked auth, logged in? ' + data.logged_in)
        })
        .catch(err => console.error('dammit son', err))
    }

    checkFB() { fbCheck(this.handleFB) }

    handleFB = response => {
        if (response.status === 'not_authorized' ||
            response.status === 'unknown') {
            data.facebook = false
        }
        else if (response.status === 'connected') {
            data.facebook = response.authResponse
        }
        this.notify('login.fb', data.facebook)
    }

    loginEmail({email, password}) {
        this.api.post('/auth/user', {email, password}).then(res => {
            if (res.status == 200) {
                data.logged_in = true
                this.checkAuth()
            }
            else {
                data.logged_in = false
            }
            this.notify('login.user', {
                authorized: data.logged_in,
                error: res.data.message
            })
        })
        .catch(console.error)
    }

}

let AppSingleton = new App()
if (process.env.NODE_ENV !== 'production') {
    window._app = AppSingleton
}
export default AppSingleton
import React, { Fragment} from 'react'
import Styled from 'styled-components'

import { monthDisplayName } from '../providers/utilities'
import Keyboard        from '../providers/keyboard'

const CalendarWrapper = Styled.div`
    width: 50vw;
    height: 50vw;
    margin: 0 auto;
`

const MonthTitle = Styled.h4`
    width: 100%;
    text-align: center;
    margin: 0;
`

const Tile = Styled.div`
    display: inline-block;
    width: calc(50vw / 8);
    height: calc(50vw / 8);
    margin-left: calc(50vw / 64);
    margin-bottom: calc(50vw / 64);
`

const DayTile = Styled(Tile)`
    cursor: pointer;
    background-color: ${p => p.theme.calendar.tile};
    border-radius: 100%;
    line-height: calc(50vw / 8);
`

const DayTileSelected = Styled(DayTile)`
    background-color: ${p => p.theme.calendar.pick};
    color: ${p => p.theme.calendar.tile};
`

const TileText = Styled.div`
    width: 100%;
    text-align: center;
    font-size: 90%;
`

export default class Calendar extends React.Component {

    componentWillMount() {
        this._ = Keyboard.listen('key', this.keyPressed.bind(this))
    }

    componentWillUnmount() {
        Keyboard.ignore(this._)
    }

    keyPressed(msg, data) {
        let selectedDay = new Date(this.props.current).getUTCDate()

        if (data.code == "Enter") {
            this.select(selectedDay, true)
            return
        }

        let modifiers = {
            ArrowLeft: -1,
            ArrowRight: +1,
            ArrowDown: +7,
            ArrowUp: -7
        }

        if (Object.keys(modifiers).indexOf(data.code) === -1) return

        data.preventDefault()
        data.stopPropagation()

        let adder = modifiers[data.code]

        let newDay = selectedDay + adder

        if (newDay < 1) return

        const {month, year} = this.props
        let thisMonth = new Date(`${month} 15, ${year}`).getUTCMonth()
        let totalDays = new Date(year, thisMonth + 1, 0).getUTCDate()

        if (newDay > totalDays) return

        this.select(newDay)
    }

    select(day, force) {
        const {year, month, onChoose} = this.props
        let mon = new Date(`${month} 15, ${year}`).getUTCMonth() + 1
        mon = String(mon).length < 2 ? '0' + mon : mon
        day = String(day).length < 2 ? '0' + day : day
        onChoose(`${year}-${mon}-${day}`, force)
    }

    render() {
        const {current, month, year, lock, onChoose} = this.props

        let validDateString = `${month} 15, ${year}`
        let currentDay = new Date(current).getUTCDate()
        let monthStart  = new Date(validDateString)
        let emptySpaces = monthStart.getUTCDay()
        let thisMonth   = monthStart.getUTCMonth()
        let totalDays   = new Date(year, thisMonth+1, 0).getUTCDate()

        let tiles = []

        for (let x = 0; x < emptySpaces; x++){
            tiles.push(<Tile key={42 + x} />)
        }

        for (let x = 1; x <= totalDays; x++) {
            let el = x == currentDay
                   ? <DayTileSelected key={x}>
                         <TileText>{x}</TileText>
                     </DayTileSelected>
                   : <DayTile key={x} onClick={() => this.select(x)}>
                        <TileText>{x}</TileText>
                   </DayTile>
            tiles.push(el)
        }

        return <div tabIndex={0} data-stop="keyboard">
            <MonthTitle>{monthDisplayName(month)}</MonthTitle>
            <CalendarWrapper>
                {tiles}
            </CalendarWrapper>
        </div>
    }
}
import React, { Fragment } from 'react'
import Styled from 'styled-components'

import {Wrapper, Item, Title} from './Choice'

import Icon from './Icon'

export default function Choice({current, items, onChoose, title, createNew}){
    function arrayFor(id) {
        let final = [parseInt(id)]
        let it = items[id]
        while (it.parent_id > 0) {
            final.unshift(parseInt(it.parent_id))
            it = items[it.parent_id]
        }
        return final
    }
    function stepUp() {
        current.pop()
        onChoose(current)
    }
    function treeString() {
        let elements = current.map(id => <span key={id}>{items[id].name} ><br /></span>)
        return elements
    }
    let end = current[current.length-1] || 0
    return  <Fragment>
        {title ? <Title>{title}</Title> : null}
        {current.length ? <Title>{treeString()}</Title> : null}
        <Wrapper>
            {end != 0 ?
                <Fragment>
                   <Item onClick={() => stepUp()}>
                        <Icon small i="circle-left" /> Back
                    </Item>
                 </Fragment>
                : null
            }
            {Object.keys(items).filter(id => items[id].parent_id == end).map(item_id => {
                return <Item key={item_id} onClick={
                    () => onChoose(arrayFor(item_id, items))
                }>{current[current.length-1] == item_id ? <Icon small i="checkmark" /> : '' } {items[item_id].name}</Item>
            })}
            <Item onClick={() => createNew()}>
                <Icon small i="plus" /> New...
            </Item>
        </Wrapper>
    </Fragment>
}
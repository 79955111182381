
export const checkLogin = (cb, repeats) => {
    // TODO: regular ol' Facebook logins and the like

    cb({status:'unknown'})
    return
    repeats = repeats || 0
    if (typeof window._fb === 'undefined') {
        setTimeout(function(){
            repeats++
            if (repeats > 49) { return cb({status:'unknown'}) }
            checkLogin(cb, repeats)
        }, 100)
        return
    } else {
        console.log('getting FB login status...')
        /**
         * Oddly, FB.getLoginStatus errors out on loading a frame...
         * So, if FB doesn't respond in a full 2 secs, assume we're logged out.
         */
        let responded = false // track response status

        // see if the user is logged in
        FB.getLoginStatus(response => {
            if (responded) return
            responded = true
            cb(response)
        }, true)

        // safeguard:
        setTimeout(() => {
            if (responded) return
            responded = true
            cb({status:'unknown'})
        }, 2000)
    }

}

export const logOut = cb => FB.logout(response => cb(response))
export const logIn  = cb => FB.login (response => cb(response))

import React, { Component, Fragment } from 'react'

import App from '../providers/app'

function Listener(RealComponent, to) {
    if (!Array.isArray(to)) to = [to]
    return class extends Component {
        constructor(props) {
            super(props)
        }
        event(msg, data) {
            this._rc.forceUpdate()
        }
        componentDidMount() {
            let handler = this.event
            let bound   = this
            if (this._rc.event) {
                handler = this._rc.event
                bound   = this._rc
            }
            this.listens = to.map(what => App.listen(what, handler.bind(bound)))
        }
        componentWillUnmount() {
            this.listens.forEach(msgs => App.ignore(msgs))
        }
        render() {
            return <RealComponent {...this.props} ref={el => this._rc = el} />
        }
    }
}

export default Listener
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Styled from 'styled-components'

export const SelectFrame = Styled.div`
    height: auto;
    position: fixed;
    right: ${props => props.position}rem;
    bottom: 4rem;
    margin: 1rem;
    min-width: 40vw;
    max-width: calc(100vw - 5rem);
    max-height: 40vh;
    border: .13rem solid ${p => p.theme.primary};
    border-radius: 1rem;
    overflow: hidden;
    overflow-y: scroll;
    background-color: ${p => p.theme.background};
`

const PopStyle = `
    padding: .5rem;
    display: block;
    text-decoration: none;
    &:first-child{
        &:hover{
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }
    }
`

export const PopItem = Styled.div`
    ${PopStyle}
    color: ${p => p.theme.popItem};
    &:hover{
        color: ${p => p.theme.background};
        background-color: ${p => p.theme.popItemHighlight};
    }
`

export const PopLink = Styled(Link)`
    ${PopStyle}
    color: ${p => p.theme.popItem};
    &:hover{
        color: ${p => p.theme.background};
        background-color: ${p => p.theme.popItemHighlight};
    }
`

export default function PopSelect (props) {
    let kidProps = {...props}
    kidProps.children = undefined
    return <Fragment>
        {props.open ?
            <SelectFrame {...kidProps}>
                {props.children}
            </SelectFrame>
            : null
        }
    </Fragment>
}
import React from 'react'
import styled from 'styled-components'

import ExpenseData from '../../providers/expenses'
import { money }   from '../../providers/utilities'

const Row = styled.div`
    border: 1px solid ${p => p.theme.primary};
    border-radius: .5rem;
    margin: .25rem;
    height: 3.5rem;
    position: relative;
`

const Base = styled.div`
    position: absolute;
    font-size: .75rem;
`

const Name = styled(Base)`
    top: .25rem;
    left: .25rem;
    font-weight: 500;
    font-size: 1.25rem;
`

const Day = styled(Base)`
    top: 1.25rem;
    right: .25rem;
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.3rem;
    text-align: center;
    border: .1rem solid ${p => p.theme.primary};
    border-top: .4rem solid ${p => p.theme.primary};
    border-radius: .3rem;
    font-size: 1rem;
`

const Amount = styled(Base)`
    top: 1.5rem;
    font-size: 1.25rem;
    right: 2.5rem;
    font-weight: 400;
`

const Method = styled(Base)`
    top: .125rem;
    right: .25rem;
`

const Category = styled(Base)`
    top: 1.75rem;
    left: .25rem;
    font-size: 1rem;
`

export default function ExpenseRow(props) {
    const { expense, select } = props
    const category = ExpenseData.getCategoryName(expense.category_tree)
    const method   = ExpenseData.getMethod(expense.method_id)
    const day      = new Date(expense.date).getUTCDate()
    const amount   = expense.original_amount > expense.amount
                   ? <b><i>{money(expense.amount_money)}</i></b>
                   : money(expense.amount_money)
    const isRecurring = expense.schedule_id > 0
    return <Row key={props.index} onClick={select}>
        <Name>
            {expense.name} {isRecurring
                            ? <span className="mi mi-reg mi-spinner9"></span>
                             : null}
        </Name>
        <Day>{day}</Day>
        <Method>{method}</Method>
        <Category>{category}</Category>
        <Amount>{amount}</Amount>
    </Row>
}
import Static from './static'

let context = []

let now = new Date()

const fallback = {
    year: () => now.getUTCFullYear(),
    month: () => Static.months[now.getUTCMonth()],
}

const sanitize = (thing) => (Static.months[thing] || thing.toLowerCase())

const monthDisplayName = function(mo){
    return Static.monthMap[mo] || Static.monthMap[fallback.month]
}

const padDateItem = item => String(item).length < 2 ? '0' + item : item

const dateString = (day, month, year) => {
    year = year || fallback.year
    month = month || fallback.month
    month = Static.monthMap[month] || month

    // todo: accept integer

    let mon = new Date(`${month} ${day}, ${year}`).getUTCMonth()+1
    mon = padDateItem(mon)
    day = padDateItem(day)
    return `${year}-${mon}-${day}`
}

const money = amount => {
    let s = (Math.round(parseFloat(amount) * 100) / 100).toFixed(2)
    let d = s.indexOf('.')
    let s2 = d === -1 ? s : s.slice(0, d)
    for (var i = s2.length - 3; i > 0; i -= 3) {
        s2 = s2.slice(0, i) + ',' + s2.slice(i)
    }
    if (d !== -1) s2 += s.slice(d)
    let currency = '$'
    if (s2.charAt(0) == '-') {
        s2 = s2.substr(1)
        if (s2.charAt(0) == ',') s2 = s2.substr(1)
        currency = '-' + currency
    }
    return currency + s2
}

const Get = function(){

    const num = context.length

    let args = []
    for (var x in arguments) {
        args.push(arguments[x])
    }

    context[num] = args

    return {
        from: function(params) {
            let ret = []
            for (var x in context[num]) {
                if (params[context[num][x]]) {
                    ret.push(sanitize(params[context[num][x]]))
                }
                else {
                    ret.push(fallback[context[num][x]]())
                }
            }
            return ret
        }
    }

    return t

}

module.exports = {
    Get,
    monthDisplayName,
    money,
    dateString,
    padDateItem
}
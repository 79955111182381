import App from './app'
import API from './api'

class Provider {

    constructor() {
        this._has        = {}
        this._fetching   = {}
        this.api         = API
    }
    // so we can quickly use this
    notify() { return App.notify.apply(App, arguments) }

    // requests
    get(path, key, code, cb, fetcher) {
        if (this._has[key]) return fetcher()
        if (this._fetching[key]) return undefined
        this._fetching[key] = true
        this.api.get(path).then(res => {
            this._fetching[key] = false
            if (res.status == code){
                this._has[key] = true
                cb(res.data)
            }
            else App.handleError(res)
        }).catch((error) => {
            console.error(error)
            this._fetching[key] = false
            App.handleError.apply(App, arguments)
        })
    }
    post(path, data, code, cb, fail) {
        this.api.post(path, data).then(res => {
            if (res.status == code) {
                cb(res.data)
            }
            else if (fail) fail()
        }).catch((error) => {
            console.error(error)
            App.handleError.apply(App, arguments)
        })
    }
    put(path, data, code, cb, fail) {
        this.api.put(path, data).then(res => {
            if (res.status == code) {
                cb(res.data)
            }
            else fail()
        }).catch((error) => {
            console.error(error)
            App.handleError.apply(App, arguments)
        })
    }
    delete(path, code, cb, fail) {
        this.api.delete(path).then(res => {
            if (res.status == code) {
                cb()
            }
            else fail()
        })
        .catch(error => {
            console.error(error)
            App.handleError.apply(App, arguments)
        })
    }

}

export default Provider
import React from 'react'
import Styled from 'styled-components'

const Title = Styled.div`
    font-size: .75rem;
    text-transform: uppercase;
    margin: .5rem 0 0 .5rem;
    color: ${p => p.theme.lighter};
    font-weight: 600;
`

export default Title
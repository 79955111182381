import React from 'react'
import Styled from 'styled-components'

const Wrapper = Styled.div`
    width: 100%;
    height: 4rem;
    position: fixed;
    bottom: 0;
    overflow:hidden;
    box-shadow: 0 0px 6px 4px ${p => p.theme.shadow};
    border-top: .01rem solid ${p => p.theme.primary};
    background-color: ${p => p.theme.background}
`

export default Wrapper
import Provider  from './provider'
import { money } from './utilities'
import Static    from './static'

class Expenses extends Provider {

    constructor() {
        super()

        // "private"
        this._expenses   = {}
        this._categories = {}
        this._methods    = {}
        this._payers     = {}
        this._paybacks   = {}
        this._expense    = {}
        this._method     = {}

        // public
        this.months      = Static.months
    }

    info (expense_id) {
        const key = `expense_${expense_id}`
        return this.get(`/expense/${expense_id}`, key, 200, data => {
            this.setExpense(expense_id, data)
        }, () => this._expense[expense_id])
    }

    deleteExpense (expense_id) {
        const expense = this._expense[expense_id]
        let {year, month} = expense

        this.delete(`/expense/${expense_id}`, 204, ok => {
            delete this._expense[expense_id]
            this._expenses[year][month] = this._expenses[year][month].filter(ex => (
                ex.expense_id != expense_id
            ))
            this.notify('expenses.deleted', {year, month})
        }, () => {
            this.notify('expenses')
        })
    }

    setExpense(expense_id, data) {
        data.amount_money = data.amount.toFixed(2)
        data.original_money = data.original_amount.toFixed(2)
        const date  = new Date(data.date)
        const month = this.months[date.getUTCMonth()]
        const year  = date.getUTCFullYear()
        let expense = {year, month, ...data}
        this.update(expense_id, expense, true)
    }

    sanitized(changes) {
        const ints   = ['method_id']
        const floats = ['original_amount']
        const translates = {
            original_amount: 'amount'
        }
        Object.keys(changes).forEach(property => {
            if (ints.indexOf(property) !== -1) {
               changes[property] = parseInt(changes[property])
            }
            else if (floats.indexOf(property) !== -1) {
                changes[property] = parseFloat(changes[property])
            }
            if (translates[property]) {
                changes[translates[property]] = changes[property]
                delete changes[property]
            }
        })
        return changes
    }

    update (expense_id, changes, internal) {
        if (!internal) changes = this.sanitized(changes)
        const original = this._expense[expense_id] || {}
        const fields   = Object.keys(changes)
        const differs  = fields.filter(x => changes[x] !== original[x])
        if (!differs.length) return
        let updated = {...original, ...changes}
        const {year, month} = updated

        this._expense[expense_id]   = updated
        this._expenses[year]        = this._expenses[year] || {}
        this._expenses[year][month] = this._expenses[year][month] || []
        let found = false
        for (let i in this._expenses[year][month]) {
            if (this._expenses[year][month][i].expense_id == expense_id) {
                this._expenses[year][month][i] = updated
                found = true
                break
            }
        }
        if (!found) {
            this._expenses[year][month].push(updated)
        }
        if (!internal) {
            this.put(`/expense/${expense_id}`, updated, 202, data => {
                this.setExpense(expense_id, data, true)
            }, () => {
                this.update(expense_id, original, true)
            })
        }
        this.notify('expenses')
    }

    set (year, month, data) {
        this._expenses[year]        = this._expenses[year] || {}
        this._expenses[year][month] = data.map(expense => {
            expense.month = month
            expense.year = year
            expense.amount_money    = expense.amount.toFixed(2)
            expense.original_money  = expense.original_amount.toFixed(2)
            return expense
        })
        return this
    }

    getMonth (year, month) {
        const key = `${year}.${month}`
        return this.get(`/expenses/${year}/${month}`, key, 200, data => {
            this.set(year, month, data)
            data.forEach(expense => {
                this._expense[expense.expense_id] = {year, month, ...expense}
                this._has[`expense_${expense.expense_id}`]= true
            })
            this.notify('expenses.month', {month})
        }, () => this._expenses[year][month])
    }

    getTotal (year, month) {
        let expenses = this.getMonth(year, month)
        if (!expenses) return null
        let sum = expenses.reduce((a,b) => (a + (b.amount || 0)), 0)
        return money(sum)
    }

    createMethod(name) {
        this.post('/expense/methods', {name}, 201, data => {
            this._methods[data.method_id] = data
            this.notify('methods.created', data)
        })
    }

    createCategory(name, parent_id) {
        let payload = {name}
        if (parent_id) payload.parent_id = parent_id
        this.post('/expense/categories', payload, 201, data => {
            this._categories[data.category_id] = data
            if (parent_id) {
                this._categories[data.parent_id]._children.push(
                    data.category_id
                )
            }
            this.notify('categories.created', data)
        })
    }

    createExpense(expense) {
        expense.method_id = parseInt(expense.method_id)
        expense.amount = parseFloat(expense.amount)
        this.post('/expense', expense, 201, data => {
            this.setExpense(data.expense_id, data, true)
        })
    }

    createPayback(expense_id, payback) {
        delete this._has[`paybacks_${expense_id}`]
        payback.method_id = parseInt(payback.method_id)
        payback.payer_id = parseInt(payback.payer_id)
        payback.amount = parseFloat(payback.amount)
        this.post(`/expense/${expense_id}/paybacks`, payback, 201, data =>{
            delete this._paybacks[expense_id]
            delete this._has[`expense_${expense_id}`]
            this.info(expense_id)
            this.getPaybacks(expense_id)
            // let expense = this.info(expense_id)
            // this.update(expense_id, {amount:expense.amount - payback.amount}, expense)
        })
    }

    getMethods() {
        return this.get('/expense/methods', 'methods', 200, data => {
            data.forEach(method => {
                this._methods[method.method_id] = method
            })
            this.notify('expenses.methods')
        }, () => this._methods)
    }

    getPayers() {
        return this.get('/expense/payers', 'payers', 200, data => {
            data.forEach(payer => {
                this._payers[payer.payer_id] = payer
            })
            this.notify('expenses.payers')
        }, () => this._payers)
    }

    getCategories() {
        return this.get('/expense/categories', 'categories', 200, data => {
            data.forEach(category => {
                this._categories[category.category_id] = category
            })
            this.notify('expenses.categories')
        }, () => this._categories)
    }

    getCategoryName(tree) {
        if (!this._has['categories']) {
            this.getCategories()
            return 'loading...'
        }
        const categoryName = tree.map(id => {
            return this._categories[id] ? this._categories[id].name : null
        }).filter(x=>x!==null).join(' > ')
        if (!categoryName) return 'N/A'
        return categoryName
    }

    getLastCategoryName(tree) {
        if (!this._has['categories']) {
            this.getCategories()
            return 'loading...'
        }
        const [id] = tree.slice(-1)
        return this._categories[id] ? this._categories[id].name : 'N/A';
    }

    getMethod(method_id) {
        if (!this._has['methods']) {
            this.getMethods()
            return 'loading...'
        }
        return (
            this._methods[method_id]
            ? this._methods[method_id].name
            : 'N/A'
        )
    }

    getPayers() {
        return this.get('/expense/payers', 'payers', 200, data => {
            data.forEach(payer => {
                this._payers[payer.payer_id] = payer
            })
            this.notify('expenses.payers')
        }, () => this._payers)
    }

    getPayerName(payer_id) {
        if (!this._has['payers']) {
            this.getPayers()
            return 'loading...'
        }
        return this._payers[payer_id].name
    }

    getPaybacks(expense_id) {
        if (!this._has['payers']) {
            this.getPayers()
            return null
        }
        const key = `paybacks_${expense_id}`
        return this.get(`/expense/${expense_id}/paybacks`, key, 200, data => {
            this._paybacks[expense_id] = data
            this.notify('expenses.payback')
        }, () => this._paybacks[expense_id])
    }

    getPayback(expense_id, payback_id) {
        if (!this._has[`paybacks_${expense_id}`]) {
            this.getPaybacks(expense_id, payback_id)
            return null
        }
        const paybacksList = this._paybacks[expense_id] || []
        return paybacksList.find(pb => pb.payback_id == payback_id)
    }

    deletePayback (expense_id, payback_id) {
        this.delete(`/expense/${expense_id}/payback/${payback_id}`, 204, ok => {
            this._paybacks[expense_id] = this._paybacks[expense_id].filter(
                pb => pb.payback_id != payback_id
            )
            this.notify('expenses.payback.deleted')
            delete this._has[`expense_${expense_id}`]
            this.info(expense_id)
        }, () => {
            this.notify('expenses')
        })
    }

    updatePayback (expense_id, payback_id, edits) {
        const original = this._paybacks[expense_id].find(pb => pb.payback_id == payback_id)
        let update = {...original, ...edits}
        update.method_id = parseInt(edits.method_id || original.method_id)
        update.amount    = parseFloat(edits.amount || original.amount)
        update.payer_id  = parseInt(edits.payer_id || original.payer_id)
        this.put(`/expense/${expense_id}/payback/${payback_id}`, update, 202, data => {
            this._paybacks[expense_id].find((pb, i) => {
                if (pb.payback_id == payback_id) {
                    this._paybacks[expense_id][i] = data
                    this.notify('expenses.payback.updated')
                    delete this._has[`expense_${expense_id}`]
                    this.info(expense_id)
                    return true
                }
                return false
            })
        }, () => {
            this.notify('expenses')
        })
    }
}

var ExpensesSingleton = new Expenses()

if (process.env.NODE_ENV !== 'production') window._expenses = ExpensesSingleton

export default ExpensesSingleton
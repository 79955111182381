import React, { Fragment } from 'react'
import { Redirect } from 'react-router'

import Listener from '../../bases/Listener'
import SidebarWrapper from '../Sidebar'
import { Button } from '../Sidebar/Buttons'
import EditMenu, { MenuItem } from '../Sidebar/EditMenu'
import Title from '../Sidebar/Title'

import ExpenseData from '../../providers/expenses'
import Calendar from '../Calendar'
import Choice from '../Choice'
import Form from '../Form'
import EditText from '../Sidebar/EditText'

const defaultState = {
    back: false,
    loading: false,

    deleting: false,
    deleted: false,

    edit: false,
    editing: false,
    edits: {}
}
class ViewSidebar extends React.Component {
    constructor(props){
        super(props)
        this._refs = {}
        this.state = defaultState
    }
    event(msg, data) {
        if (msg == 'expenses.payback.deleted')
            this.setState({deleted: true})
        else if (msg == 'expenses.payback.updated')
            this.setState({loading: false, edit: false, editing: false})
    }
    reset() {
        this.setState(defaultState)
    }
    back() {
        this.setState({back: true})
    }
    edit({name, value}) {
        const {edits} = this.state
        this.setState({edits:{...edits, [name]: value}})
    }
    save() {
        const {edits} = this.state
        const {expense_id, payback_id} = this.props.match.params
        this.setState({loading: true}, () => {
            ExpenseData.updatePayback(expense_id, payback_id, edits)
        })
    }
    delete() {
        const {payback_id, expense_id} = this.props.match.params
        this.setState(
            {loading: true},
            () => ExpenseData.deletePayback(expense_id, payback_id)
        )
    }
    componentDidUpdate(prevProps, prevState) {
        const state = this.state
        const types = ['editing']
        types.forEach(type => {
            const it = state[type]
            if (!prevState[type] && !!it) {
                if (this._refs[it]) {
                    let el = this._refs[it]
                    el.focus()
                    /**
                     * Some user agents are annoying and don't allow for us to
                     * sel a range within a number input, so hack around that
                     * by changing the input to 'text', then sel, then go back.
                     */
                    let swap = el.type == 'number' ? 'number' : false

                    if (swap) el.type = 'text'

                    if (el.type == 'text') {
                        setTimeout(() => {
                            el.setSelectionRange(0, el.value.length)
                            if (swap) el.type = swap
                        }, 0)
                    }
                }
            }
        })
    }
    render() {
        const {payback_id, expense_id} = this.props.match.params
        const {back, deleting, deleted, loading, editing, edit, edits} = this.state

        const payback = ExpenseData.getPayback(expense_id, payback_id)
        const expense = ExpenseData.info(expense_id)
        const methods = ExpenseData.getMethods()
        const payers  = ExpenseData.getPayers()

        const method_items = {}
        if (methods) {
            Object.keys(methods).forEach(method_id => {
                method_items[method_id] = methods[method_id].name
            })
        }
        const payer_items = {}
        if (payers) {
            Object.keys(payers).forEach(payer_id => {
                payer_items[payer_id] = payers[payer_id].name
            })
        }
        if (back || deleted) return <Redirect to={
            `/expense/${expense_id}/view`
        } />
        if (loading) return <Fragment>
            <SidebarWrapper />
            <EditMenu>loading...</EditMenu>
        </Fragment>
        let CancelButton = <Button position="1" icon="x" onClick={
            () => this.reset()
        } />
        let CancelWrapper = <SidebarWrapper>{CancelButton}</SidebarWrapper>
        if (deleting) return <Fragment>
            {CancelWrapper}
            <EditMenu>
                <Title>Delete Payback?</Title>
                <MenuItem onClick={() => this.delete()}>Yes</MenuItem>
                <MenuItem onClick={() => this.setState({deleting: false})}>
                    No
                </MenuItem>
            </EditMenu>
        </Fragment>
        if (editing) {
            let SidebarElement = <SidebarWrapper>
                {CancelButton}
                <Button position="2" icon="check" onClick={
                    () => this.save()
                } />
            </SidebarWrapper>
            let EditMenuElement;
            if (editing == 'paid') {
                EditMenuElement = <EditMenu>
                    <Calendar
                        year={expense.year}
                        month={expense.month}
                        lock="month"
                        current={edits[editing] ? edits[editing] : payback[editing]}
                        onChoose={(e, s) => this.edit({name: editing, value:e}, s)}
                    />
                </EditMenu>
            }
            else if (editing == 'method_id') {
                EditMenuElement = <EditMenu>
                    <Choice
                        name={editing}
                        title="Payment Method"
                        current={edits[editing] ? edits[editing] : payback[editing]}
                        items={method_items}
                        onChoose={e => this.edit({name: editing, value: e})}
                    />
                </EditMenu>
            }
            else if (editing == 'amount') {
                let amount = edits[editing] ? edits[editing] : payback[editing]
                EditMenuElement = <EditMenu>
                    <Form send={() => this.save()}>
                        <Title>Amount</Title>
                        <EditText
                            name={editing}
                            ref={x => this._refs[editing] = x}
                            id={editing}
                            step="0.01"
                            type='number'
                            value={amount}
                            onChange={e => this.edit(e.target)}
                        />
                    </Form>
                </EditMenu>
            }
            else if (editing == 'payer_id') {
                EditMenuElement = <EditMenu>
                    <Choice
                        title="Payer"
                        name={editing}
                        current={edits[editing] ? edits[editing] : payback[editing]}
                        items={payer_items}
                        onChoose={e => this.edit({name: editing, value: e})}
                    />
                </EditMenu>
            }
            return <Fragment>
                {SidebarElement}
                {EditMenuElement}
            </Fragment>
        }
        if (edit) return <Fragment>
            {CancelWrapper}
            <EditMenu>
                <MenuItem onClick={
                    () => this.setState({editing: 'payer_id'})
                }>Payer</MenuItem>
                <MenuItem onClick={
                    () => this.setState({editing: 'amount'})
                }>Amount</MenuItem>
                <MenuItem onClick={
                    () => this.setState({editing: 'method_id'})
                }>Payment Method</MenuItem>
                <MenuItem onClick={
                    () => this.setState({editing: 'paid'})
                }>Date</MenuItem>
            </EditMenu>
        </Fragment>
        return <Fragment>
            <SidebarWrapper>
                <Button position="1" icon="arrow-left" onClick={() => this.back()} />
                <Button position="2" icon="trash-2" onClick={
                    () => this.setState({deleting: true})
                } />
                <Button position="3" icon="edit" onClick={
                    () => this.setState({edit: true})
                } />
            </SidebarWrapper>
        </Fragment>
    }
}

export default Listener(ViewSidebar, ['expenses'])
import React from 'react'
import styled from 'styled-components'

import PaybackRow from '../../components/Expenses/Paybacks/PaybackRow'

const PaybacksBox = styled.div`
    width: 100%;
    max-height: 25%;
    overflow: hidden;
    overflow-y: scroll;
`

const Paybacks = ({list}) => {
    return <PaybacksBox>
        {list.map(payback => (
            <PaybackRow key={payback.payback_id} payback={payback} />
        ))}
    </PaybacksBox>
}

export default Paybacks
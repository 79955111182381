import React from 'react'
import Styled from 'styled-components'

export const EditMenu = Styled.div`
    height: auto;
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    margin: 1rem;
    min-width: 40vw;
    max-width: calc(100vw - 4rem);
    max-height: calc(100vh - 5rem);
    border: .13rem solid ${p => p.theme.primary};
    border-radius: 1rem;
    overflow: hidden;
    overflow-y: scroll;
    background-color: ${p => p.theme.background};
`

export const MenuItem = Styled.div`
    width: 100%;
    font-size: 1rem;
    padding: .5rem;
    padding-left: 1rem;
    cursor: pointer;
    &:hover{
        background-color: ${p => p.theme.hightlight};
    }
`

export default EditMenu
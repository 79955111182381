import React from 'react'
import Styled from 'styled-components'

const Spin = Styled.span`
    animation: spin 4s linear infinite;
`

export default function Icon({i, small, spin}) {
    let icn = <span className={`mi ${small ? 'mi-sm' : ''} mi-${i}`}></span>
    if (spin) icn = <Spin>{icn}</Spin>
    return icn
}
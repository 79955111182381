import React from 'react'
import Styled from 'styled-components'

export const HeaderRow = Styled.tr`
    border-bottom: .01rem solid white;
`

export const HeaderField = Styled.th`
    padding: .25rem;
    text-align: right;

    &:nth-child(1){
        text-align: left;
    }
`
import React, { Fragment } from 'react'

import App from '../providers/app'

import Listener from '../bases/Listener'

class Status extends React.Component {

    state = {
        profile: null
    }

    constructor(){
        super()
        this._listens = ['user']
    }

    componendDidMount() {
        this.setState({profile: App.userProfile()})
    }

    event(msg, data) {
        if (msg == 'user.profile') {
            this.setState({profile: data})
        }
    }

    render() {
        let {profile} = this.state
        if (!profile) return null
        return <h2>{profile.first_name} {profile.last_name}</h2>
    }

}

export default Listener(Status, ['user'])
import React from "react";
import Styled from "styled-components";

import Form from "../../elements/Form";

const Loading = () => <p>loading...</p>;

const FormLabel = Styled.label`
    display: block;
    margin: .5rem 0;
    font-size: 1rem;
`;

const FormInput = Styled.input`
    font-size: .75rem;
    padding: .5rem;
    border-radius: .25rem;
`;

const SubmitButton = Styled.button`
    padding: .5rem;
    font-size: 0.75rem;
    background-color: ${(p) => p.theme.primary};
    border: none;
    border-radius: .25rem;
    color: ${(p) => p.theme.background};
`;

const CredentialsForm = Styled(Form)`
    text-align: left;
    border: 2px solid ${p => p.theme.primary};
    padding: 2rem;
    border-radius: 1rem;
`;

export default class LoginForm extends React.Component {
  state = {
    email: null,
    password: null,
  };

  checkAndSubmit = (x) => {
    x.preventDefault();
    let { email, password } = this.state;
    this.props.action({ email, password });
  };

  render() {
    let { loading } = this.props;
    if (loading) return <Loading />;
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: '4rem' }}>
        <CredentialsForm onSubmit={this.checkAndSubmit}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <FormInput
            id="email"
            type="email"
            onChange={(x) => this.setState({ email: x.target.value })}
          />
          <br />
          <FormLabel htmlFor="password">Password</FormLabel>
          <FormInput
            id="password"
            type="password"
            onChange={(x) => this.setState({ password: x.target.value })}
          />
          <br />
          <br />
          <div style={{ textAlign: "center" }}>
            <SubmitButton>Log In</SubmitButton>
          </div>
        </CredentialsForm>
      </div>
    );
  }
}

import React from 'react'
import styled from 'styled-components'

import Icon from '../Icon'

import IncomeData from '../../providers/incomes'

const Row = styled.div`
    border: 1px solid ${p => p.theme.primary};
    border-radius: .5rem;
    margin: .25rem;
    height: 5.5rem;
    position: relative;
`

const Base = styled.div`
    position: absolute;
`

const Name = styled(Base)`
    top: .5rem;
    left: .5rem;
    font-weight: 500;
    font-size: 1.25rem;
`

const Day = styled(Base)`
    top: .5rem;
    right: .5rem;
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.3rem;
    text-align: center;
    border: .1rem solid ${p => p.theme.primary};
    border-top: .4rem solid ${p => p.theme.primary};
    border-radius: .3rem;
`

const Amount = styled(Base)`
    bottom: .5rem;
    font-size: 1.5rem;
    right: .5rem;
    font-weight: 500;
`

const Method = styled(Base)`
    top: 2.25rem;
    left: .5rem;
`

const Category = styled(Base)`
    bottom: .5rem;
    left: .5rem;
`

export default function IncomeRow(props) {
    const { income, select } = props
    const category           = IncomeData.getCategoryName(income.category_id)
    const method             = IncomeData.getMethodName(income.method_id)
    const day                = new Date(income.date).getUTCDate()
    const amount             = income.amount_money
    const isRecurring        = income.schedule_id > 0
    return <Row key={props.index} onClick={select}>
        <Name>
            {income.name} {isRecurring ? <Icon small i="spinner9" /> : null }
        </Name>
        <Day>{day}</Day>
        <Method>{method}</Method>
        <Category>{category}</Category>
        <Amount>{amount}</Amount>
    </Row>
}
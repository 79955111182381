import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
    font-weight: 700;
    position: relative;
    left: .25rem;
    margin: .25rem;
`

export default function Header(props) {
    return <Title>{props.children}</Title>
}
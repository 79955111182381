import React, { Fragment } from 'react'
import Styled from 'styled-components'

import Logo from '../images/x-180.png'

const LoadWrap = Styled.div`
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 75%;
`

export default function LoadingScreen({msg}) {
    return <LoadWrap>
        <img src={Logo} />
        <br />
        <p>{msg}</p>
    </LoadWrap>
}
import Styled from 'styled-components'

export const Container = Styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: .25rem;
    padding-bottom: 4rem;
`

export default Container